import React from 'react';
import './MyAccountHeader.scss';
import Button from './../Button';
import { setCustomerBranch, setCustomerID, setCustomerPostcode, setCustomerStatus } from '../../helpers/functions';
import { clearBasketRequest } from '../../actions/Order';
import { config } from '../../config/config';


type AccountContent = {
  title?: string;
};
const MyAccountHeader = ( props: AccountContent) => {

  const {
    title = 'My Account',  
  } = props;

  const onLogout = () => {
    clearBasketRequest();
    setCustomerID('');
    setCustomerStatus('');
    setCustomerBranch('');
    setCustomerPostcode('');
    window.location.href = window.location.href; //
    // history.push(c.APP_ROUTES.LOGIN);
  };
  return (
    <div className="myaccount-header" style={{ backgroundImage: `url("${config.myAccount.headerImg}")` }}>
      <div className='container'>
        <div className="myaccount-header__title">
          <h3>{ props.title }</h3>
        </div>
        <div className="myaccount-header__logout">
          <Button text="Logout" type="grey" onClick={onLogout} />
        </div>
      </div>
    </div>
  );
};

export default MyAccountHeader;

