import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../helpers/history';
import { config } from '../config/config';
import c from '../helpers/constants';
import { fetchCustomer } from '../actions/Customer';
import { didFetchingCustomerFailed } from '../selectors/Customer';
import '../styles/Login.scss';
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import { getCustomer } from '../selectors/Customer';
import LWModal from '../components/Modal';
import { emailForgotPassword } from '../actions/Email';
import CookieConsent from 'react-cookie-consent';
import { NavLink } from 'react-router-dom';
import { setCustomerBranch, setCustomerStatus } from '../helpers/functions';
import {
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { Capacitor } from '@capacitor/core';
const Login = () => {

  const dispatch = useDispatch();
  const [customerId, setCustomerId] = useState('');
  const [postcode, setPostcode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [clickedOnce, setClickedOnce] = useState(false);
  const fetchingFailed = useSelector(didFetchingCustomerFailed);
  const customer = useSelector(getCustomer);
  const [toggle, setToggle] = useState(false);
  const [name, setName] = useState('');
  const [cname, setCName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const imgs = config.loginPage.images;
  const [imgIndex, setImgIndex] = useState(0);
  const [inProgress, setInProgess] = useState<boolean>(false);



  const setStrokeData = () => {
    const radius = 26;
    let angle = (((imgIndex + 1) / imgs.length)) * 360;
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = (angle / 360) * circumference;
    const strokeOffset = ((imgIndex + 1) / imgs.length) * circumference;

    // 6. Create dash array of two elements (combined they must equal the entire circumference).
    //    First has the length of visible portion. Second, the remaining part.
    let dash = [
      strokeDasharray,
      circumference - strokeDasharray
    ];

    return [
      dash,
      strokeOffset + 'px'
    ];
  };


  let strokeData = setStrokeData();
  const strokeDasharray = strokeData[0];
  const dashOffset = strokeData[1];

  const onLogin = async () => {
    setIsLoading(true);
    setClickedOnce(true);
    await dispatch(fetchCustomer(customerId, postcode, true));
    setIsLoading(false);
  };


  const onDecline = () => {
    let x = document.getElementById('tagmanager');
    if (x != null) {
      x.remove();
    }
    let y = document.getElementById('tagmanager-script');
    if (y != null) {
      y.remove();
    }
  };

  useEffect(() => {
    if (!fetchingFailed && customer !== null) {
      history.push(c.APP_ROUTES.DASHBOARD);
    }
  }, [customer]);



  const isFormReady = postcode?.length > 0 && customerId.length > 0;
  const onContact = () => {
    window.location.href = 'mailto:' + config.registration_email;
  };

  const onForgotPassword = async () => {
    setInProgess(true);
    await dispatch(emailForgotPassword(
      {
        contactEmail: email,
        contactName: cname,
        contactTel: tel,
        customerName: name,
        customerId: customerId,
      },
    ));
    setCustomerId('');
    setCustomerBranch('');
    setCustomerStatus('');
    setName('');
    setCName('');
    setEmail('');
    setTel('');
    setEmailSent(true);
    setInProgess(false);
  };



  const [showBanner, setShowBanner] = useState<boolean>(true);

  const setDeclineCookie = () => {
    localStorage.setItem('cookieconsent', 'false');
  };

  let cookieConsent = localStorage.getItem('cookieconsent');




  const getStatus = async () => {
    const response = await AppTrackingTransparency.getStatus();
    return response;
  }




  const requestPermission = async () => {
    const response = await AppTrackingTransparency.requestPermission();
    return response;
  };


  const isRunningOnNative = Capacitor.isNativePlatform();



  const requestTrackingPermission = async () => {
    try {
      const statusRes = await getStatus();
      const status = statusRes.status;


      if (status === 'notDetermined') {
        const result = await requestPermission();
        const resText = result.status;
        if (resText === 'authorized') {
          localStorage.setItem('cookieconsent', 'true');
          setShowBanner(false);
        } else {
          setShowBanner(false);
          localStorage.setItem('cookieconsent', 'false');
          let x = document.getElementById('tagmanager');
          if (x != null && x != undefined) {
            x.remove();
          }
          let y = document.getElementById('tagmanager-script');
          if (y != null && y != undefined) {
            y.remove();
          }
        }
      } else if (status == 'denied') {
        setShowBanner(false);
        //Remove the cookie stuff 
        let x = document.getElementById('tagmanager');
        if (x != null && x != undefined) {
          x.remove();
        }
        let y = document.getElementById('tagmanager-script');
        if (y != null && y != undefined) {
          y.remove();
        }
      }
    } catch (error) {
      console.error('Error requesting tracking permission', JSON.stringify(error));
    }
  };

  useEffect(() => {
    if (isRunningOnNative) {
      requestTrackingPermission();
    }
  }, [])


  let presentBanner = false;

  if (isRunningOnNative) {
    presentBanner = showBanner;
  }
  else {
    presentBanner = cookieConsent != 'false' && cookieConsent != 'true';
  }


  return (
    <div>
      <div className="page-container login-outer">
        <div className='row'>

          <div className='col-lg-6'>

            <div className="login-container">
              <img alt="Likewise Logo" src={config.likewiseLogo} />
              <h3 className='mt-4'>Login to your Account</h3>
              <div className="pt-3 pb-4 sign-in-to">Sign in to {config.companyName}</div>

              <div className="mb-3 mt-4 input-container">
                <TextInput label="Customer Account No*" id="customerId" bgColor="grey" name="customerId"
                  value={customerId} onBlur={(e) => setCustomerId(e)}
                  style="normal"
                  onChange={(e) => setCustomerId(e)}
                  placeholder="Enter your customer account number here" />
              </div>
              <div className="mb-3 mt-2 input-container">
                <TextInput label="Password*" bgColor="grey" id="postcode" name="postcode"
                  value={postcode} onBlur={(e) => setPostcode(e)}
                  style="normal"
                  type="password"
                  onChange={(e) => setPostcode(e)}
                  placeholder="Enter your password here" />
              </div>

              {fetchingFailed && customerId && postcode && clickedOnce &&

                <div className="mt-4 mb-4 error-message-alert">
                  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Phase-4---UI-Design-Amendments" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="1">
                      <g id="Individual-Product-Page-1" transform="translate(-1534.000000, -778.000000)" fill="#C84645" fill-rule="nonzero">
                        <g id="add" transform="translate(1547.000000, 791.000000) rotate(-315.000000) translate(-1547.000000, -791.000000) translate(1538.000000, 782.000000)">
                          <path d="M16.3928571,7.39285714 L10.9285714,7.39285714 C10.7510637,7.39285714 10.6071429,7.24893625 10.6071429,7.07142857 L10.6071429,1.60714286 C10.6071429,0.719604482 9.88753838,0 9,0 C8.11246162,0 7.39285714,0.719604482 7.39285714,1.60714286 L7.39285714,7.07142857 C7.39285714,7.24893625 7.24893625,7.39285714 7.07142857,7.39285714 L1.60714286,7.39285714 C0.719604482,7.39285714 0,8.11246162 0,9 C0,9.88753838 0.719604482,10.6071429 1.60714286,10.6071429 L7.07142857,10.6071429 C7.24893625,10.6071429 7.39285714,10.7510637 7.39285714,10.9285714 L7.39285714,16.3928571 C7.39285714,17.2803955 8.11246162,18 9,18 C9.88753838,18 10.6071429,17.2803955 10.6071429,16.3928571 L10.6071429,10.9285714 C10.6071429,10.7510637 10.7510637,10.6071429 10.9285714,10.6071429 L16.3928571,10.6071429 C17.2803955,10.6071429 18,9.88753838 18,9 C18,8.11246162 17.2803955,7.39285714 16.3928571,7.39285714 Z" id="Path"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  Wrong customer number or password</div>
              }

              <div className="mt-1 text-right forgot-password-button">
                <Button onClick={() => setToggle(true)} type="link" text="Forgot Password?" />
              </div>

              <div className="mt-3 input-container submit-button">
                <Button disabled={isLoading || !isFormReady} onClick={onLogin} size="stretch" text={isLoading ? 'Loading...' : 'Login'} />
              </div>

              <div className="mt-6 text-left text-grey contact-support">
                <p>Don&apos;t have an account?
                  <Button onClick={onContact} type="link" text={config.loginPage.register} />
                </p>
              </div>

              <div className="footer-bottom__copyright text-grey text-left">
                <p>© {new Date().getFullYear()} {config.copyrightText} Designed and Powered by <a href='https://www.sketchcreative.co/' target="_blank" rel="noreferrer">Sketch Creative</a>.</p>
              </div>
            </div>
          </div>
          <div className='col-lg-6 login-img-column'>
            <div className='img-rotator' style={{ backgroundImage: `url("${config.loginPage.images[imgIndex]}")` }}>
              <div className="rotator-inner">
                <div className="graph">
                  <svg>
                    <circle cx="30" cy="30" r="26" stroke-dasharray={strokeDasharray} stroke-dashoffset={dashOffset} />
                  </svg>
                  <span className="graph-counter">
                    <span>{imgIndex + 1}/</span><span>{imgs.length}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <LWModal title="Forgot Password" onClose={() => {
        setToggle(false);
        setEmailSent(false);
      }}
        show={toggle}>
        {!emailSent &&
          <div>
            <div className="row">
              <div className="col-12">
                <TextInput label="Customer Account Number" id="customerId" bgColor="grey" name="customerId"
                  value={customerId} onBlur={(e) => setCustomerId(e)}
                  onChange={(e) => setCustomerId(e)}
                  placeholder="Enter your customer account number here" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput label="Customer Name" id="name" bgColor="grey" name="name"
                  value={name} onBlur={(e) => setName(e)}
                  onChange={(e) => setName(e)}
                  placeholder="Enter your customer name here" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput label="Contact Name" id="cname" bgColor="grey" name="cname"
                  value={cname} onBlur={(e) => setCName(e)}
                  onChange={(e) => setCName(e)}
                  placeholder="Enter your contact name here" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput label="Contact Email" id="email" bgColor="grey" name="email"
                  value={email} onBlur={(e) => setEmail(e)}
                  onChange={(e) => setEmail(e)}
                  placeholder="Enter your contact email here" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput label="Contact Phone" id="tel" bgColor="grey" name="tel"
                  value={tel} onBlur={(e) => setTel(e)}
                  onChange={(e) => setTel(e)}
                  placeholder="Enter your contact phone here" />
              </div>
            </div>
            <div className="row mt-5">
              <Button text="Request Password" disabled={!email || !tel || !cname || !name || inProgress} onClick={onForgotPassword} />
            </div>
          </div>
        }
        {emailSent && <div className="alert alert-success" role="alert">
          Our support team will be in touch soon to help resolve this issue.
        </div>}
      </LWModal>

      {presentBanner &&
        <CookieConsent
          location='bottom'
          buttonText='Accept'
          cookieName='cookieconsentCookie'
          style={{ background: '#403f3e', paddingLeft: '20px', paddingRight: '20px' }}
          buttonStyle={{ background: config.fillColour, color: '#FFF', borderRadius: '10px', fontSize: '14px' }}
          expires={150}
          enableDeclineButton
          declineButtonText='Opt Out'
          onAccept={() => {
            setDeclineCookie();
          }}
          onDecline={() => {

            setDeclineCookie();

            let x = document.getElementById('tagmanager');
            if (x != null && x != undefined) {
              x.remove();
            }
            let y = document.getElementById('tagmanager-script');
            if (y != null && y != undefined) {
              y.remove();
            }
          }}
        >
          This website uses cookies to enhance the user experience. <NavLink to={c.APP_ROUTES.COOKIES}>Learn More</NavLink>
        </CookieConsent>
      }
    </div>

  );
};

export default Login;
