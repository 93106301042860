import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_AVAILABLE_BANKS_FAILURE,
  FETCH_AVAILABLE_BANKS_RESPONSE,
  FETCH_AVAILABLE_BANKS_REQUEST,
  AvailableBanks,
  AvailableBanksAction,
  AvailableBankObj,
} from '../types/AvailableBanks';

import { RootState } from '../store/configureStore';
import { getAvailableBanks, triggerBankPaymentAction, triggerPaymentAction, triggerStatementPayment } from '../api/AvailableBanksApi';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import history from '../helpers/history';
import c from '../helpers/constants';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { getInvoiceData } from '../api/InvoiceApi';

export function fetchAvailableBanksRequest(): AvailableBanksAction {
  return {
    type: FETCH_AVAILABLE_BANKS_REQUEST,
    payload: null,
  };
}

export function fetchAvailableBanksResponse(
  availableBanks: AvailableBankObj,
): AvailableBanksAction {
  return {
    type: FETCH_AVAILABLE_BANKS_RESPONSE,
    payload: availableBanks,
  };
}

export function fetchAvailableBanksFailure(): AvailableBanksAction {
  return {
    type: FETCH_AVAILABLE_BANKS_FAILURE,
    payload: null,
  };
}

export const fetchAvailableBanks = ():
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAvailableBanksRequest());
    if (!isAuthenticated()) {
      try {
        history.push(c.APP_ROUTES.LOGIN);
      } catch (e) {
      }
    }
    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any = await getAvailableBanks(finalCustomerId);

    if (asyncResp) {
      await dispatch(fetchAvailableBanksResponse(asyncResp));
    } else {
      await dispatch(fetchAvailableBanksFailure());
    }
  };


export const listBanks = ():
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }

    let cust = getCustomerID();

    if (!cust) {
      return null;
    }


    const asyncResp = await getAvailableBanks(cust);

    console.log(asyncResp);
    if (asyncResp.return_code === 'OK') {
    } else {
    }
  };

export const payOrder = (orderNumber: number):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }

    const asyncResp = await triggerPaymentAction(orderNumber);
    console.log(asyncResp);
    if (asyncResp.return_code === 'OK') {
    } else {
    }
  };


export async function getInvoice(invoiceNumber: number, trnno: number ) {
  if (!isAuthenticated()) {
    history.push(c.APP_ROUTES.LOGIN);
  }
  let customerId = getCustomerID();

  const asyncResp = await getInvoiceData(customerId as string, invoiceNumber, trnno);

  if (asyncResp.return_code === 'ok') {
    return asyncResp.dsInvoice;
  } else {
    return null;
  }
};



export const payByBank = async (orderNumber: number, orderValue: number, bank: string) => {
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }

    const asyncResp = await triggerBankPaymentAction(orderNumber, orderValue, bank);

    if (asyncResp.Data) {
      let url = asyncResp.Data.ResponseURL;
      return url;
    } else {
      return null;
    }
  };





export const payInvoicesByBank = async (data: any, orderValue: number, bank: string) => {
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }

    const asyncResp = await triggerStatementPayment(data, orderValue, bank);

    if (asyncResp.Data) {

      //Open it up 

      let url = asyncResp.Data.ResponseURL;


      return url;
    } else {
      return null;
    }
  };