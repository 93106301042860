import React, { useEffect, useState } from 'react';
import '../styles/Cart.scss';
import View from '../components/View';
// import Search from '../assets/svgs/searchLW.svg';
// import Visa from '../assets/svgs/visa.svg';
// import Lock from '../assets/svgs/lock.svg';
import c from '../helpers/constants';
import CardCheckout from '../components/CardCheckout';
import Button from '../components/Button';
import MobileMenuBottom from '../components/MobileBottomMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getBasket, getDeliveryCharge, getDeliveryDates } from '../selectors/Order';
import { addToBasket, clearBasket, placeOrder } from '../actions/Order';
import { fetchCustomer } from '../actions/Customer';
import { getCustomer } from '../selectors/Customer';
import _ from 'lodash';
import history from '../helpers/history';
import { getCustomerStatus, roundNumber } from '../helpers/functions';
import DropDown from '../components/DropDown';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import TextInput from '../components/TextInput';
import { DeliveryAddress } from '../types/Customer';
import exclamation from '../assets/svgs/excmark.svg';
import { ToggleButton } from 'react-bootstrap';
import TextArea from '../components/Textarea';
import { config } from '../config/config';
import { NavLink } from 'react-router-dom';
import CONSTANTS from '../helpers/constants';

const Cart = () => {

    const orderLines = useSelector(getBasket);
    const dispatch = useDispatch();
    const customer = useSelector(getCustomer);
    const deliveryDates = useSelector(getDeliveryDates);
    const carriage = useSelector(getDeliveryCharge);

    const [customerRef, setCustomerReference] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [specialRequest, setSpecialRequest] = useState('');
    const [deliveryCode, setDeliveryCode] = useState(customer?.delivery_address_code);
    const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress | null>();

    const collectionEnabled = true;
    const [collection, setCollection] = useState(false);
    let hasPresold = false;


    const onDelete = (index: number) => {
        const localOrderLines = orderLines?.filter((o, i) => i !== index);
        dispatch(clearBasket());
        dispatch(addToBasket(localOrderLines, customer?.delivery_address_code, true));
    };

    const filterDeliveryDates = () => {

        let dd = [...deliveryDates];

        //Any day 
        if (collection) {
            return dd;
        }

        if (customer?.drop_ship == true) {
            return [dd[0]];
        }

        let ddOpts: any = [];
        if (deliveryAddress == null) {
            ddOpts = customer?.delivery_addresses[0].del_days;
        } else {
            ddOpts = deliveryAddress.del_days;
        }

        if (!ddOpts) {
            return dd;
        }


        let outDates = [];
        for (let i = 0; i < dd.length; i++) {
            // Assuming the input date format is DD/MM/YY
            const inputDate = dd[i];
            // Split the date string into day, month, and year components
            const [day, month, year] = inputDate.split('/');

            const convertedDate = new Date(parseInt(`20${year}`), parseInt(month) - 1, parseInt(day));

            const dayOfWeek = convertedDate.getDay();


            if (!ddOpts[dayOfWeek]) {
                continue;
            }
            outDates.push(dd[i]);
        }

        return outDates;
    };

    const filteredDeliveryDates = deliveryDates ? filterDeliveryDates() : [];

    let totalWithoutVat = 0;

    orderLines.forEach(x => {
        if (x.po_dates != null && x.po_dates.length > 0) {

            if (x.line_delivery_date == null || x.line_delivery_date == '') {
                x.line_delivery_date = x.po_dates[0];
            }

            if (config.enablePresold) {
                hasPresold = true;
            }

        }
    });

    const setCustomerRef = (value: string) => {
        value = value.replace(/`/g, "'");
        let sanitizedValue = value.replace(/[^0-9a-zA-Z\s']/g, '');
        setCustomerReference(sanitizedValue);
    };

    const setDValue = (value: string, parameter: string) => {
        let localAddr: DeliveryAddress = {
            addcode: '',
            country: '',
            address_2: parameter == 'address_2' ? value : ((deliveryAddress != null) ? (deliveryAddress as DeliveryAddress).address_2 : ''),
            NAME: parameter == 'NAME' ? value : ((deliveryAddress != null) ? (deliveryAddress as DeliveryAddress).NAME : ''),
            address_1: parameter == 'address_1' ? value : ((deliveryAddress != null) ? (deliveryAddress as DeliveryAddress).address_1 : ''),
            town: parameter == 'town' ? value : ((deliveryAddress != null) ? (deliveryAddress as DeliveryAddress).town : ''),
            county: parameter == 'county' ? value : ((deliveryAddress != null) ? (deliveryAddress as DeliveryAddress).county : ''),
            pcode: parameter == 'pcode' ? value : ((deliveryAddress != null) ? (deliveryAddress as DeliveryAddress).pcode : ''),
        };
        setDeliveryAddress(localAddr);
    };

    const onChangeLineValue = (index: number, field: string, value: string) => {
        const localOrderLines = orderLines;

        switch (field) {
            case 'deliveryDate':
                localOrderLines[index].line_delivery_date = value;
                break;
            case 'collection':
                localOrderLines[index].collection = (value === 'collection');
                break;
        }

        dispatch(clearBasket());
        dispatch(addToBasket(localOrderLines, customer?.delivery_address_code, false, true));
    };

    const setDeliveryDates = (date: string, collection: boolean) => {

        console.log(date);

        orderLines?.forEach(o => {
            if (!o.po_dates || o.po_dates.length == 0) {
                o.line_delivery_date = date;
            }
            o.collection = collection;
        });
        setCollection(collection);
        setDeliveryDate(date);
    };

    useEffect(() => {

        let x = document.getElementById('shipping_options');

        if (x) {
            window.scrollTo(0, x.offsetTop - 120);
        }
    }, []);

    //Add up the lines
    orderLines.forEach(x => {
        totalWithoutVat += x.line_price;
    });

    const onDashboard = () => {
        history.push(c.APP_ROUTES.DASHBOARD);
    };

    let filledAllDates = orderLines?.length == orderLines?.filter(x => { return x.line_delivery_date != null && x.line_delivery_date != ''; }).length;

    const onPlaceOrder = () => {
        const finalDeliveryCode = deliveryCode || customer?.delivery_address_code || '01';
        if ((deliveryDate || filledAllDates) && (finalDeliveryCode || (customer?.drop_ship && deliveryAddress))) {
            let address = null != customer && customer.drop_ship ? (deliveryAddress as DeliveryAddress) : null;
            dispatch(placeOrder(deliveryDate, finalDeliveryCode, address, collection, specialRequest, customerRef));
        }
    };

    const validAddress = () => {

        if (customer?.drop_ship != true) {
            return true;
        }

        let deliveryKeys = ['NAME', 'address_1', 'town', 'pcode'];

        if (deliveryAddress == null) {
            return false;
        }

        //Check that the values are set
        for (let i = 0; i < deliveryKeys.length; i++) {
            if ((deliveryAddress as any)[deliveryKeys[i]] == null || ((deliveryAddress as any)[deliveryKeys[i]] == '')) {
                return false;
            }
        }

        return true;
    };


    const generateDirectionsLink = () => {
        let arr = [];
        if (!customer || undefined == customer.branch_name) {
            return '';
        }
        if (customer.branch_name) {
            arr.push(customer.branch_name);
        }
        if (customer.branch_address2) {
            arr.push(customer.branch_address2);
        }
        if (customer.branch_town) {
            arr.push(customer.branch_town);
        }
        if (customer.branch_pcode) {
            arr.push(customer.branch_pcode);
        }
        let query = arr.join(',');
        query = query.replace(/ /g, '+');
        return 'https://www.google.com/maps?saddr=My+Location&daddr=' + query;
    };

    const directionsLink = generateDirectionsLink();

    useEffect(() => {
        dispatch(fetchCustomer());
    }, [dispatch]);


    let deliveryAddresses = customer?.delivery_addresses != undefined ? customer.delivery_addresses.map(d => ({
        label: [d.address_1, d.address_2, d.town, d.pcode].filter(n => n).join(', '),
        value: d.addcode,
    })).filter(x => x.label.trim() != '') || [] : [];


    if (deliveryAddresses.length == 0 && deliveryAddress == undefined) {
        //Dispatch this onto the state
        let custAdd = [];
        if (customer?.address_1) {
            custAdd.push(customer?.address_1);
        }
        if (customer?.town) {
            custAdd.push(customer?.town);
        }
        if (customer?.county) {
            custAdd.push(customer?.county);
        }
        if (customer?.pcode) {
            custAdd.push(customer?.pcode);
        }
        deliveryAddresses.push({ value: '01', label: custAdd.join(',') });
    }


    let counter = 0;

    return (
        <View>
            {config.showBreadcrumbOnCheckout == true &&
                <div className='breadcumb checkout-breadcrumb'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='container-fluid'>
                                <ul>
                                    <li><NavLink exact={true} to={CONSTANTS.APP_ROUTES.DASHBOARD}>Home</NavLink></li>
                                    <li><span>&rsaquo;</span></li>
                                    <li><span>Checkout</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 orders">
                        <div className="orders__title">
                            <h3>Order Summary</h3>
                        </div>
                        {carriage > 0 &&
                            <div>
                                <div className='alert alert-warning cart-alert'>
                                    <img src={exclamation} className='mr-3 exclamation' /> A carriage charge of &pound;{carriage.toFixed(2)} will be applied to this order.
                                </div>
                            </div>
                        }
                        <div className="orders__search">
                            <div className="orders__search__left">
                                <div className="orders__search__left__text">
                                    {config.showBreadcrumbOnCheckout == false &&
                                        <p>Your Order</p>
                                    }
                                    {config.showBreadcrumbOnCheckout == true &&
                                        <p>There are {orderLines.length} items in your order</p>
                                    }
                                </div>
                            </div>
                            <div className="orders__search__right">
                                <p><span>Total (excl VAT):</span> <span>£{roundNumber(totalWithoutVat)}</span></p>
                            </div>
                        </div>
                        {orderLines.map((o, i) => <CardCheckout
                            key={i}
                            lineNo={i}
                            imageCode={o.product_sku}
                            onDelete={() => onDelete(i)}
                            onChange={(field: string, val: string) => onChangeLineValue(i, field, val)}
                            quality={o.product_quality}
                            category={o.product_type_code_name}
                            categoryCode={o.product_type_code}
                            linePrice={o.line_price}
                            itemPrice={o.item_price}
                            quantity={o.quantity}
                            length={o.length || 0}
                            width={o?.width || 0}
                            reference={o.line_reference}
                            color={o.colour_full_name}
                            deliveryDate={o.line_delivery_date}
                            deliveryDates={filteredDeliveryDates}
                            collection={o.collection}
                            sqmPrice={o.price_per_sqm || 0}
                            poDates={o.po_dates}
                            isDropShip={customer?.drop_ship == true}
                        />)}
                    </div>

                    <div className="col-lg-4 payment pb-5" id="shipping_options">

                        <div className="customer__reference__outer mb-2">
                            <div className="customer__reference__title">
                                <h4 className="pb-2">Customer Reference</h4>
                            </div>
                            <div className="customer__reference__input pt-3">
                                <TextInput id="customerreference" bgColor="grey" name="customerreference"
                                    value={customerRef != null ? customerRef : ''}
                                    style='normal'
                                    onChange={(e) => setCustomerRef(e)}
                                    placeholder="Customer Reference" />
                            </div>
                        </div>

                        {collectionEnabled &&
                            <div className="shipping-options mt-5">
                                <h4 className="mb-4">
                                    Shipping Options
                                </h4>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="collection"
                                            onChange={() => {
                                                setDeliveryDates(deliveryDate, false);
                                            }}
                                            checked={!collection}
                                        />
                                        &nbsp;Delivery
                                    </label>
                                </div>
                                <div className="pt-2">
                                    <label>
                                        <input
                                            type="radio"
                                            name="collection"
                                            onChange={() => {
                                                setDeliveryDates(deliveryDate, true);
                                            }}
                                            checked={collection}
                                        />
                                        &nbsp;Collection
                                    </label>
                                </div>
                            </div>
                        }

                        {!collection &&
                            <div className="payment__billing">

                                {customer?.drop_ship != true &&
                                    <div className="payment__billing__address">
                                        <div className="payment__billing__address__title">
                                            <h4 className="pb-2">Shipping Address</h4>
                                            <p className='text-dark-grey'>Select Address from Address Book</p>
                                        </div>
                                        <div className="payment__billing__address__link pt-3">
                                            <DropDown items={deliveryAddresses} value={deliveryCode}
                                                placeholder="Select another address"
                                                onSelect={(item) => {
                                                    setDeliveryCode(item.value);
                                                    // @ts-ignore
                                                    setDeliveryAddress(customer?.delivery_addresses?.find(d => d.addcode === item.value));
                                                }} />
                                        </div>
                                    </div>
                                }
                                <h4 className="pb-2 pt-2">Shipping Address</h4>
                                {customer?.drop_ship != true &&
                                    <p className="text-dark-grey">
                                        {/* @ts-ignore */}
                                        {_.startCase(_.toLower(deliveryAddress?.NAME || customer?.NAME))}
                                        <br />
                                        {/* @ts-ignore */}
                                        {_.startCase(_.toLower(deliveryAddress?.address_1 || customer?.address_1))}
                                        <br />
                                        {/* @ts-ignore */}
                                        {_.startCase(_.toLower(deliveryAddress?.town || customer?.town))}
                                        <br />
                                        {/* @ts-ignore */}
                                        {_.startCase(_.toLower(deliveryAddress?.county || customer?.county))}
                                        <br />
                                        {/* @ts-ignore */}
                                        {deliveryAddress?.pcode || customer?.pcode}
                                    </p>
                                }
                                {customer?.drop_ship == true && <div>
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <TextInput id="name" bgColor="grey" name="name"
                                                value={deliveryAddress != null ? (deliveryAddress as DeliveryAddress).NAME : ''} onBlur={(e) => setDValue(e, 'NAME')}
                                                onChange={(e) => setDValue(e, 'NAME')}
                                                placeholder="Customer Name" />
                                        </div>
                                    </div>
                                    <div className="row  mb-2">
                                        <div className="col-12">
                                            <TextInput id="address_1" bgColor="grey" name="address_1"
                                                value={deliveryAddress != null ? (deliveryAddress as DeliveryAddress).address_1 : ''} onBlur={(e) => setDValue(e, 'address_1')}
                                                onChange={(e) => setDValue(e, 'address_1')}
                                                placeholder="Address Line One" />
                                        </div>
                                    </div>
                                    <div className="row  mb-2">
                                        <div className="col-12">
                                            <TextInput id="address_1" bgColor="grey" name="address_2"
                                                value={deliveryAddress != null ? (deliveryAddress as DeliveryAddress).address_2 : ''} onBlur={(e) => setDValue(e, 'address_2')}
                                                onChange={(e) => setDValue(e, 'address_2')}
                                                placeholder="Address Line Two" />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <TextInput id="town" bgColor="grey" name="town"
                                                value={deliveryAddress != null ? (deliveryAddress as DeliveryAddress).town : ''} onBlur={(e) => setDValue(e, 'town')}
                                                onChange={(e) => setDValue(e, 'town')}
                                                placeholder="Town/City" />
                                        </div>
                                    </div>
                                    <div className="row  mb-2">
                                        <div className="col-6">
                                            <TextInput id="county" bgColor="grey" name="county"
                                                value={deliveryAddress != null ? (deliveryAddress as DeliveryAddress).county : ''} onBlur={(e) => setDValue(e, 'county')}
                                                onChange={(e) => setDValue(e, 'county')}
                                                placeholder="County" />
                                        </div>
                                        <div className="col-6">
                                            <TextInput id="pcode" bgColor="grey" name="pcode"
                                                value={deliveryAddress != null ? (deliveryAddress as DeliveryAddress).pcode : ''} onBlur={(e) => setDValue(e, 'pcode')}
                                                onChange={(e) => setDValue(e, 'pcode')}
                                                placeholder="Post Code" />
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        }

                        <div className="payment__billing">
                            <h4 className="pb-2">Billing Address</h4>
                            <div className="text-dark-grey">
                                {_.startCase(_.toLower(customer?.NAME))}
                                <br />
                                {_.startCase(_.toLower(customer?.address_1))}
                                <br />
                                {_.startCase(_.toLower(customer?.town))}
                                <br />
                                {_.startCase(_.toLower(customer?.county))}
                                <br />
                                {customer?.pcode}
                            </div>
                        </div>

                        <div className="payment__details mt-5">
                            <h4 className='mb-2 flex justify-content-start align-items-center'><IoIosInformationCircleOutline color='#e26329' className='orange-text mr-3' /><span>Update Details</span></h4>
                            <p className="text-dark-grey">
                                If you need to change or update your billing details, please contact
                                us: <a href="tel:{customer?.branch_phone}">{customer?.branch_phone}</a>
                            </p>
                        </div>

                        {(!collection && !hasPresold) &&
                            <>
                                <div className="payment__date">
                                    <h4>{config.cart.deliveryDate.title}</h4>
                                    {config.cart.deliveryDate.instructions.map((text) =>
                                        <p className="text-dark-grey pt-2">{text}</p>
                                    )}
                                </div>
                            </>
                        }

                        {(collection && !hasPresold) &&
                            <div className="collection">
                                <div className="collection__date">
                                    <h4>Collection</h4>
                                    <p className="text-dark-grey pt-2">
                                        Please select your preferred collection date below. To ensure your
                                        order is ready for collection, please call your local branch to let
                                        us know you are on your way.
                                    </p>
                                </div>
                                <div className="collection__branch">
                                    <h4>Branch</h4>
                                    <div className="collection__branch__info pt-2">
                                        <div className="collection__branch__address">
                                            <p>
                                                {customer?.branch_name ? customer?.branch_name.toLowerCase() : ''}
                                            </p>
                                            <p>
                                                {customer?.branch_address1 ? customer?.branch_address1.toLowerCase() : ''}
                                            </p>
                                            <p>
                                                {customer?.branch_address2 ? customer?.branch_address2.toLowerCase() : ''}
                                            </p>
                                            <p>
                                                {customer?.branch_town ? customer?.branch_town.toLowerCase() : ''}
                                            </p>
                                            <p>
                                                {customer?.branch_pcode ? customer?.branch_pcode.toUpperCase() : ''}
                                            </p>
                                        </div>
                                        <div className="collection__branch__other">
                                            <p>
                                                <span className="text-orange">Office:</span> {customer?.branch_phone}
                                            </p>
                                            <p>
                                                <span className="text-orange">Email:</span><span className='lower'>{customer?.branch_email}</span>
                                            </p>
                                            <p className="mt-2">
                                                <a className="text-orange" href={directionsLink} target="_blank">Get Directions</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-4">
                                    <h4>Collection Date</h4>
                                    <p className="pt-2">Select collection date</p>
                                </div>
                            </div>
                        }

                        {!hasPresold &&
                            <div className="payment__date__info pt-3">
                                <div className="payment__date__info__details">
                                    <div className="payment__date__info__details__text">
                                        <DropDown
                                            items={filteredDeliveryDates?.map(d => ({ label: d, value: d }))}
                                            value={deliveryDate}
                                            onSelect={(item) => setDeliveryDates(item.value, collection)}
                                            placeholder={(collection == false) ? "Delivery Date (Full Order)" : "Collection Date (Full Order)"}
                                        />
                                    </div>
                                </div>
                            </div>
                        }


                        {customer?.is_matting &&
                            <div className="payment__billing">
                                <h4 className="pb-2">Special Instructions</h4>
                                <div className="text-dark-grey">
                                    <TextArea style='normal' placeholder='Special Instructions' value={specialRequest} onChange={setSpecialRequest} onBlur={setSpecialRequest}></TextArea>
                                </div>
                            </div>
                        }

                        <div className="d-flex justify-content-between pt-5">
                            <h4>Order Summary</h4>
                        </div>
                        <div className="d-flex justify-content-between pt-3">

                            <div className="">
                                <p className="text-dark-grey">Subtotal Excl VAT</p>
                            </div>
                            <div className="text-dark-grey">£{roundNumber(totalWithoutVat)}</div>
                        </div>
                        {carriage > 0 &&
                            <div className='pt-3'>
                                <div className='alert alert-warning square-alert alert-small d-flex justify-content-between align-items-center'>
                                    <span><img src={exclamation} className='mr-2 exclamation' />Carriage Charge</span><span>&pound;{carriage.toFixed(2)}</span>
                                </div>
                            </div>
                        }
                        <div className="d-flex justify-content-between pt-3">
                            <div className="">
                                <p className="text-dark-grey">VAT (20%)</p>
                            </div>
                            <div className="text-dark-grey">£{roundNumber((totalWithoutVat + carriage) * 0.2)}</div>
                        </div>
                        <div className="d-flex justify-content-between pt-3">
                            <div className="">
                                <p className="text-orange total-text">
                                    <b>Total</b>
                                </p>
                            </div>
                            <div className="">
                                <p className="text-orange total-text">
                                    <b>£{roundNumber((totalWithoutVat + carriage) * 1.2)}</b>
                                </p>
                            </div>
                        </div>
                        <div className="mt-4 cart-btn">
                            <Button size="stretch" disabled={(!deliveryDate && !filledAllDates) || !validAddress()} onClick={onPlaceOrder} text="Place Order" />
                        </div>
                        <div className="mt-5 d-flex justify-content-center">
                            <a onClick={onDashboard} className="continue-shopping text-grey"><span className="text-grey">Continue Shopping</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <MobileMenuBottom />

        </View>
    );
};

export default Cart;
