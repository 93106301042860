import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_INVOICES_FAILURE,
  FETCH_INVOICES_RESPONSE,
  FETCH_INVOICES_REQUEST,
  Invoice,
  InvoiceActionTypes,
} from '../types/Invoice';

import { RootState } from '../store/configureStore';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import history from '../helpers/history';
import c from '../helpers/constants';
import { getInvoices } from '../api/InvoiceApi';

export function fetchInvoicesRequest(): InvoiceActionTypes {
  return {
    type: FETCH_INVOICES_REQUEST,
    payload: null,
  };
}

export function fetchInvoicesResponse(
  statements: Invoice[],
): InvoiceActionTypes {
  return {
    type: FETCH_INVOICES_RESPONSE,
    payload: statements,
  };
}

export function fetchInvoicesFailure(): InvoiceActionTypes {
  return {
    type: FETCH_INVOICES_FAILURE,
    payload: null,
  };
}

export const fetchInvoices = ():
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchInvoicesRequest());

    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }

    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any = await getInvoices(finalCustomerId);
  

    console.log( asyncResp.return_code ); 

    if (asyncResp.return_code === 'OK') {
      console.log( asyncResp['tt-slTranList']);
      await dispatch(fetchInvoicesResponse(asyncResp['tt-slTranList']));
    } else {
      await dispatch(fetchInvoicesFailure());
    }
  };
