import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { config } from './../config/config';
import axios from 'axios';
import { stat } from 'fs';

let lwConstSessionId = "";

export const roundNumber = (num: number) => _.round(num, 2).toFixed(2);

export const roundFloat = (num: number, digits: number) => _.round(num, digits).toFixed(digits);

export const roundInteger = (num: number) => _.round(num, 0).toFixed(0);

export const forceFloat = ( num: any ) => {
  return parseFloat('' + num);
};


export const checkUnitPrice = ( qty: number, prices: any ) => {
  if( null == prices ){
    return 0;
  }

  if( prices.quantity_price.length == 0){
    return prices.unit_price; 
  }

  if( config.enableQtyBreaks == false){
    return prices.unit_price; 
  }

  prices.quantity_price.sort((a:any, b:any) => {
    return a.qty_quantity - b.qty_quantity;
  });

  let price = prices.unit_price; 
  for( let i = 0; i < prices.quantity_price.length; i++ ){
    if( qty >= prices.quantity_price[i].qty_quantity ){
      price = prices.quantity_price[i].qty_price;
    }
  }    
  return price;
};


export const setUid = ( id: string ) => {
  lwConstSessionId = id; 
}

export const getUid = ( ) => {
  return lwConstSessionId;
}



export const formatDate = ( d : any ) => {
  return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
    d.getFullYear();
};

export const generateSessionID  = () => {
  let time = new Date().getTime(); 
  let rand = Math.random() * 10000;
  let id = makeid( 10 ); 
  let uid = CryptoJS.MD5(`${rand}${time}${id}`).toString();
  return uid; 
};

export const generateUID = ( sku: string, quality: string, ref: string ) => {
    let time = new Date().getTime(); 
    let uid = CryptoJS.MD5(`${sku}${quality}${ref}${time}`).toString();
    return uid; 
};

export const getCategoryImage = (category: string ) => {

  switch (category) {
    case 'AM':
      return  config.A_Accessories_Image_Matting;
    case 'A':
      return config.A_Accessories_Image;
    case 'B':
      return config.B_Adhesives_Screeds_Image;
    case 'C':
      return config.C_Carpet_Image;
    case 'E':
      return config.E_Contract_Image;
    case 'G':
      return config.G_Grass_Image;
    case 'H':
      return config.H_Underlay_Image;
    case 'L':
      return config.L_LVT_Image;
    case 'N':
      return config.N_Laminate_Image;
    case 'O':
      return config.O_Mats_Runners_Image;
    case 'Q':
      return config.Q_Matting_Image;
    case 'R':
      return config.R_Rugs_Image;
    case 'T':
      return config.T_CarpetTile_Image;
    case 'V':
      return config.V_Banner_Vinyl_Image;
    case 'SP':
      return config.Category_Specials;
    default:
      return config.GenericBanner;
  }
};

export const getCategoryBannerImage = (category?: string) => {
  switch (category) {
    case 'A':
      return config.A_Accessories;
    case 'AM':
      return config.A_Accessories_Matting;
    case 'B':
      return config.B_Adhesives_Screeds;
    case 'C':
      return config.C_Carpet;
    case 'E':
      return config.E_Contract;
    case 'G':
      return config.G_Grass;
    case 'H':
      return config.H_Underlay;
    case 'L':
      return config.L_LVT;
    case 'N':
      return config.N_Laminate;
    case 'O':
      return config.O_Mats_Runners;
    case 'Q':
      return config.Q_Matting;
    case 'R':
      return config.R_Rugs;
    case 'T':
      return config.T_CarpetTile;
    case 'V':
      return config.V_Banner_Vinyl;
    case 'MB':
      return config.mattingBanner[0];
    case 'SP':
        return config.Banner_Specials;
    default:
      return config.GenericBanner;
  }
};

const lPrefix = 'LKWS_';
const hash = 'D8bsf7HmHJHm0srBm9Bcy6iC';
const lKeys = {
  CUSTOMER_ID: 'CUSTOMER_ID',
  CUSTOMER_POSTCODE: 'CUSTOMER_POSTCODE',
  AUTH_COOKIE_LAST_REQUESTED: 'AUTH_COOKIE_LAST_REQUESTED',
  API_KEY: 'API_TOKEN',
  CUSTOMER_BRN: 'CUSTOMER_BRN',
  CUSTOMER_STAT: 'CUSTOMER_STATUS',
  CUSTOMER_BANK: 'CUSTOMER_BANK'
};


export const makeid = (length:number ) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const renderCurrentLength = (input: any | null) => {

  //console.log(input);

  if (input == null) {
    return 0;
  }
  if (input.toString().endsWith('.')) {
    return input;
  }

  if (input.toString().endsWith('.0')) {
    return input;
  }

  if (input == 0) {
    return input;
  }


  return Number(input).toString();
};

export const lwRoundNumber = (input: any, allowEmpty?: boolean) => {

  if (allowEmpty && input == null || input == '') {
    return input;
  }

  if (input.toString().endsWith('.')) {
    return input;
  }
  if (input.toString().endsWith('.0')) {
    return input;
  }

  const num = parseFloat(parseFloat(input).toFixed(2));
  const decimals = parseInt(((num % 1) * 100).toFixed(0));
  const numberX = Math.trunc(num);
  const roundToFive = Math.ceil(decimals / 5) * 5;
  const result = numberX + (roundToFive / 100);
  return result > 0 ? result : 0;
};

export const objectToQueryString = (obj: any) => _.reduce(obj, (result, value, key) => {
  return (!_.isNull(value) && !_.isUndefined(value)) ? (result += key + '=' + value + '&') : result;
}, '').slice(0, -1);

export const digestMessage = async (message: string) => { // ex: 'WF10 5NU01/09/21'
  const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string ;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getApiKey = () => {
  return localStorageGetItem(lKeys.API_KEY);
};

export const setApiKey = ( key: string ) => {
  return localStorageSetItem(lKeys.API_KEY, key );
};

const localStorageSetItem = (key: string, value: string) => {
  window.localStorage.setItem(`${lPrefix}${key}`, value);
};

const localStorageGetItem = (key: string) => {
  return window.localStorage.getItem(`${lPrefix}${key}`);
};

export const getCustomerID = () => {
  return localStorageGetItem(lKeys.CUSTOMER_ID);
};


export const setCustomerID = (customerId: string) => {
  localStorageSetItem(lKeys.CUSTOMER_ID, customerId);
};

export const getCustomerBranch = () => {
  return localStorageGetItem(lKeys.CUSTOMER_BRN);
};


export const setCustomerBranch = (brn: string) => {
  localStorageSetItem(lKeys.CUSTOMER_BRN, brn);
};

export const setCustomerStatus = ( status: string ) => {
  localStorageSetItem( lKeys.CUSTOMER_STAT, status ); 
}

export const getCustomerSelectedBank = () => {
  return localStorageGetItem(lKeys.CUSTOMER_BANK);
};
export const setCustomerSelectedBank = ( bankId: string ) => {
  localStorageSetItem(lKeys.CUSTOMER_BANK, bankId);
};


export const getCustomerStatus = () => {
  return localStorageGetItem( lKeys.CUSTOMER_STAT ); 
}

export const getCustomerPostcode = () => {
  const val = localStorageGetItem(lKeys.CUSTOMER_POSTCODE);
  // @ts-ignore
  return val ? CryptoJS.AES.decrypt(val, hash).toString(CryptoJS.enc.Utf8) : val;
};

export const setCustomerPostcode = (postcode: string) => {
  const val = CryptoJS.AES.encrypt(postcode, hash);
  localStorageSetItem(lKeys.CUSTOMER_POSTCODE, val.toString());
};



export const setTitleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};


export const  filterType = ( inStr: string, changeCase: boolean ) => {
    if( inStr.toLowerCase() == 'contract' ){
        return changeCase ? 'COMMERCIAL ROLLS' : 'Commercial Rolls'
    }
    return changeCase ? inStr : inStr.toLowerCase();
};

export const formatMayneDate = (inDate: string ) => {
  let x = inDate.split('-');
  return `${x[2]}/${x[1]}/${x[0]}`; 
  
}
export const getXMonthsAgo = ( months: number, start?: string ) => {
  let today = new Date();

  if( start ){
    let parts = start.split("/");
  
    // Make sure to parse the parts as integers
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1; // Months are 0-indexed in JavaScript Date (January is 0)
    let year = parseInt(parts[2], 10);
  
    // Create a new date object using the parsed values
    today = new Date(year, month, day);
  }

  // Subtract 6 months
  today.setMonth(today.getMonth() - months);
  
  // Get the year, month, and day
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let day = String(today.getDate()).padStart(2, '0');
  
  // Format the date as YYYY-MM-DD
  return `${day}/${month}/${year}`;
};

export const getXDaysAgo = ( days: number, altFormat?: boolean ) => {
  let today = new Date();

  // Subtract 6 months
  today.setDate(today.getDate() - days);
  
  // Get the year, month, and day
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let day = String(today.getDate()).padStart(2, '0');
  
  if( altFormat ){
    return `${year}-${month}-${day}`;
  }

  // Format the date as YYYY-MM-DD
  return `${day}/${month}/${year}`;
};

let axiosToken  : any = null; 

export const getAxiosToken = () => {
  return axiosToken;
};

export const setAxiosToken = () => {
  axiosToken = axios.CancelToken.source();
}


export const setOrderSource = ( source: string ) => {
  localStorageSetItem('orderSource', source);
};

export const isRepOrder = () => {
  let src = localStorageGetItem('orderSource');
  return src ? src == 'reppw' : false;
};


// Function to detect browser and device information
export const  getBrowserAndDeviceInfo = () =>  {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;

  // Detect the browser
  let browserName = "Unknown Browser";
  if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edge") === -1) {
      browserName = "Google Chrome";
  } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
      browserName = "Safari";
  } else if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
      browserName = "Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      browserName = "Opera";
  }

  // Detect the device type
  let deviceType = "Unknown Device";
  if (/Mobile|Android|iP(ad|hone|od)/i.test(userAgent)) {
      deviceType = "Mobile";
  } else if (/Tablet|iPad/i.test(userAgent)) {
      deviceType = "Tablet";
  } else if (/Win|Mac|Linux/i.test(platform)) {
      deviceType = "Desktop";
  }

  return {
      browser: browserName,
      device: deviceType,
      userAgent: userAgent,
      platform: platform
  };
}

export const calculateOutstanding = (line: any) => {
  let g = parseFloat(line.fgoods);
  let vat = parseFloat(line.fvat);
  let paid = parseFloat(line.fpaid);
  let total = parseFloat((g + vat).toFixed(2)) - paid;
  let x = total.toFixed(2);

  if( x == '-0.00'){
    return '0.00';
  }
  return x; 
};

function getRandomIntExclusive(min: number, max:number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const isAuthenticated = () => !(!getCustomerPostcode() || !getCustomerID());
