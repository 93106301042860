import React, { useEffect, useState } from 'react';
import './Header.scss';
import { useDispatch, useSelector } from 'react-redux';
import c from '../../helpers/constants';
import { config } from './../../config/config';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { getBasket } from '../../selectors/Order';
import CONSTANTS from '../../helpers/constants';
import { getCustomer } from '../../selectors/Customer';
import { fetchCustomer } from '../../actions/Customer';
import LWModal from '../Modal/Modal';
import ChangePassword from '../ChangePassword';
import { clearBasketRequest } from '../../actions/Order';
import { getCustomerID, setCustomerBranch, setCustomerID, setCustomerPostcode, setCustomerStatus } from '../../helpers/functions';
import HeaderTicker from '../HeaderTicker';

const Header = () => {
  const basket = useSelector(getBasket);

  const dispatch = useDispatch();
  const [customerId, setCustomerId] = useState('');
  const [toggle, setToggle] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const enablePricelists = config.hasOwnProperty('enablePricelists') && config.enablePricelists;
  const customer = useSelector(getCustomer);

  useEffect(() => {
    setCustomerId(getCustomerID() as string);
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCustomer());
  }, [dispatch]);


  const onLogout = () => {
    clearBasketRequest();
    setCustomerID('');
    setCustomerStatus('');
    setCustomerBranch('');
    setCustomerPostcode('');
    window.location.href = window.location.href;
  };

  return (
    <>
      <div className='navbar-outer'>
        {
          (config.enableSpecials && config.headerBanner != null && config.headerBanner.length > 0) && <HeaderTicker></HeaderTicker>
        }
        <Navbar bg="white" variant="light" expand="lg" className="nav-container">

          <Navbar.Toggle aria-controls="navbarScroll" >
            <label>
              <span></span>
              <span></span>
              <span></span>
            </label>
          </Navbar.Toggle>
          <Navbar.Brand as={Link} to={c.APP_ROUTES.DASHBOARD}>
            <img src={config.likewiseLogo} className="navbar-brand" alt={config.companyName + ' Logo'} />
          </Navbar.Brand>
          <div className="desktop-hide">
            <NavLink to={c.APP_ROUTES.CART}>
              {basket?.length > 0 && <div className="cart-indicator">{basket.length}</div>}

              <img src={config.cartIcon} className="" alt="search-icon" />

            </NavLink>
          </div>
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto navbarFlex">

              <NavLink to={c.APP_ROUTES.DASHBOARD} exact className="nav-link" activeClassName="active">{config.menuLabels.home}</NavLink>
              <NavLink to={c.APP_ROUTES.PRODUCTS} exact className="nav-link" activeClassName="active">{config.menuLabels.cats}</NavLink>
              <NavLink to={c.APP_ROUTES.ORDERS} exact className="nav-link" activeClassName="active">{config.menuLabels.orders}</NavLink>
              {config.showAbout &&
                <NavLink to={c.APP_ROUTES.ABOUT} exact className="nav-link" activeClassName="active">{config.menuLabels.about}</NavLink>
              }
              <NavLink to={c.APP_ROUTES.CONTACT} exact className="nav-link" activeClassName="active">{config.menuLabels.contact}</NavLink>
              <NavLink to={c.APP_ROUTES.ACCOUNT} exact className="nav-link" activeClassName="active">{config.menuLabels.account}</NavLink>
              <NavLink to={c.APP_ROUTES.SEARCH} className="mobile-hide">
                <img src={config.searchIcon} className="nav-link" alt="Search" />
              </NavLink>
              <NavLink to={c.APP_ROUTES.CART} className="nav-link">
                {basket?.length > 0 && <div className="cart-indicator mobile-hide">{basket.length}</div>}
                <img src={config.cartIcon} className="mobile-hide" alt="search-icon" />
                <span className="desktop-hide">Basket ({basket.length})</span>
              </NavLink>
              <NavLink to={c.APP_ROUTES.SEARCH} className="desktop-hide nav-link">
                <img src={config.searchIcon} className="nav-link" alt="Search" />

              </NavLink>


              <NavDropdown title={<><span className='account-no'>Account No:</span><span className='text-red'>{customerId}</span></>} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to={CONSTANTS.APP_ROUTES.ACCOUNT}>Account Information</NavDropdown.Item>

                <NavDropdown.Item as={Link} to={CONSTANTS.APP_ROUTES.ORDERS}>{config.menuLabels.orders}</NavDropdown.Item>
                {(enablePricelists && !customer?.is_matting) &&
                  <NavDropdown.Item as={Link} to={CONSTANTS.APP_ROUTES.PRICELIST}>My Pricelist</NavDropdown.Item>
                }




                <NavDropdown.Item as={Link} to={CONSTANTS.APP_ROUTES.ACCOUNT_SUMMARY}>{config.menuLabels.accountSummary}</NavDropdown.Item>


                {(config.enablePayments == true) &&

                  <NavDropdown.Item as={Link} to={CONSTANTS.APP_ROUTES.MY_STATEMENTS}>Account Statement</NavDropdown.Item>

                }
                <NavDropdown.Item as={Link} to={CONSTANTS.APP_ROUTES.UPDATE_ACCOUNT_INFO}>{config.menuLabels.updateAccount}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setToggle(true)}>{config.menuLabels.changePassword}</NavDropdown.Item>
                <NavDropdown.Item onClick={onLogout}>{config.menuLabels.logout}</NavDropdown.Item>
              </NavDropdown>


            </Nav>
          </Navbar.Collapse>

        </Navbar>
      </div>
      <LWModal title="Change Password" onClose={() => {
        setToggle(false);
        setEmailSent(false);
      }}
        show={toggle}>
        <ChangePassword></ChangePassword>
      </LWModal>
    </>
  );
};

export default Header;
