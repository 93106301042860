import React, { useEffect, useState } from 'react';
import './SelectBank.scss';
import { getAvailableBanks } from '../../selectors/AvailableBanks';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAvailableBanks, payByBank, payInvoicesByBank } from '../../actions/AvailableBanks';
import Button from '../Button';
import { getReturnLines } from '../../selectors/Order';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { config } from '../../config/config';
import { Payit } from '../../helpers/icons';
import { getCustomerSelectedBank, setCustomerSelectedBank } from '../../helpers/functions';


type Props = {
    orderNumber?: number,
    orderValue: number,
    type: 'statement' | 'order';
    data?: any
};
const SelectBank = (props: Props) => {


    const { orderNumber, orderValue, type, data } = props;


    const dispatch = useDispatch();
    const bankObj = useSelector(getAvailableBanks);
    const [selectedBank, setSelectedBank] = useState<any>(null);
    const [showconsent, setShowConsent] = useState<boolean>(false);
    let banks = bankObj ? bankObj.banks : [];
    const termsLink = bankObj ? bankObj.paymentTerms : '';
    const [loading, setLoading] = useState<boolean>(false);
    const [showRedirecting, setShowRedirecting] = useState<boolean>(false);
    const [link, setLink] = useState<string>('');

    //Get the selected bank 
    let selectedCustomerBank = getCustomerSelectedBank();

    if (banks) {
        // banks.sort((x, y) => x.BankName.localeCompare(y.BankName));
    }

    if (selectedCustomerBank && selectedCustomerBank != '') {
        let theBank = banks.find(x => x.BankID == selectedCustomerBank);

        //Pop customers bank to the top of the list 
        if (theBank) {
            banks = banks.filter(bank => bank.BankID !== selectedCustomerBank); // Exclude the selected bank
            banks.unshift(theBank); // Add it to the top
        }
    }


    let filteredBanks = banks ? banks.filter(x => x.BankTemporarilyUnavailable == false) : [];

    useEffect(() => {
        dispatch(fetchAvailableBanks());
    }, []);



    const selectBank = (bank: any) => () => {
        //  await triggerPaymentAction(); 
        setSelectedBank(bank);
    };


    const goToNext = () => {
        if (!selectedBank) {
            alert('Please select a bank');
            return;
        }
        setCustomerSelectedBank(selectedBank.BankID);
        setShowConsent(true);
    };

    const isRunningOnNative = Capacitor.isNativePlatform();

    const openTerms = () => {
        if (isRunningOnNative) {
            Browser.open({ url: termsLink });
            return;
        }
        window.open(termsLink, '_blank');
    };

    const cancel = () => {
        setShowConsent(false);
    }

    const complete = async () => {
        setLoading(true);
        let link = "";
        if (type == 'statement') {
            link = await (payInvoicesByBank(data, props.orderValue, selectedBank.BankID));
        } else {
            link = await (payByBank(props.orderNumber as number, props.orderValue, selectedBank.BankID));
        }

        setLink(link);

        setLoading(false);
    }


    useEffect(() => {
        if (link && link != '') {
            setShowConsent(false);
            setShowRedirecting(true);
            setTimeout(() => {

                const isRunningOnNative = Capacitor.isNativePlatform();

                if (isRunningOnNative) {
                    Browser.open({ url: link });
                    return;
                }

                window.location.href = link;
            }, config.payit.bankRedirectionSeconds);
        }
    }, [link]);


    return (
        <div className={'d-table w-100 select-bank'}>
            <div className='select-bank-header'>
                <div className="payment-modal__icon d-flex justify-content-between align-items-center">
                    <div className='pay-by-bank-title'>
                        Pay by Bank
                    </div>
                    <div className="d-flex justify-content-between align-items-center">

                        <div className="payit-by">
                            <div className="payit-brand mr-2">
                                {
                                    Payit()
                                }
                            </div>
                            <div className="payit-small">
                                Powered by Natwest
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='custom-modal-body'>

                {
                    showconsent &&
                    <div className='show-consent-overlay'>
                        <div className='show-consent-inner'>
                            <p>
                                Payit™ is provided by NatWest. By continuing, you agree to NatWest initiating the payment(s) and agree to these <a onClick={() => openTerms()} className='text-orange'>terms</a>.
                            </p>
                            <div className='text-center mt-3'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <Button text="Cancel" shape={'chamfer'} onClick={() => cancel()} size={'md'} type={'grey'}></Button>
                                    </div>
                                    <div>
                                        <Button text="Continue" shape={'chamfer'} onClick={() => complete()}></Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }

                {
                    showRedirecting &&
                    <div className='show-consent-overlay'>
                        <div className='show-consent-inner'>
                            <p>
                                You will now be redirected to your bank to complete your payment.
                            </p>
                            <div className='the-loader'>
                                <div className="loader"></div>
                            </div>
                        </div>
                    </div>
                }

                <div className='anti-fraud mb-2'>
                    {
                        config.payit.antiFraudMessage
                    }
                </div>

                <div className='bank-list'>
                    {
                        filteredBanks.map((bank, index) => (
                            <div key={index} onClick={selectBank(bank)} className={selectedBank == bank ? 'bank-item item-selected' : 'bank-item'}>
                                <img src={bank.LogoUrl} alt={bank.BankName} />
                                <span>{bank.BankName}</span>
                            </div>
                        ))
                    }

                </div>


                <div className='text-center mt-3'>
                    <Button text="Next" shape={'chamfer'} onClick={() => goToNext()} disabled={selectedBank == null} size={'md'}></Button>
                </div>

                {
                    loading && <>


                    </>
                }

            </div>
        </div>
    );
};

export default SelectBank;
