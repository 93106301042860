import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseButtonSVG from '../../assets/svgs/modal-close.svg';
import './LwBankModal.scss';

type Props = {
  title: string;
  byText: string;
  children: any,
  onClose: () => void;
  show: boolean;
};
const LwBankModal = (props: Props) => {
  return (
    <Modal size="lg" show={props.show} contentClassName='bank-modal'   onHide={props.onClose} >  
      <Modal.Body>
        {props.children}
        
        <div className='modal-close' onClick={props.onClose}>
        <img onClick={props.onClose} className="lw-modal-close-button" src={CloseButtonSVG}
                     alt="modal-close-button"/>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LwBankModal;
