import React, { useEffect, useState } from "react";

import './PaymentModal.scss';
import { CheckSquare, CheckSquareBlank, CreditCard } from "../../helpers/icons";
import Button from "../Button";
import TextInput from "../TextInput";
import { triggerPaymentAction } from "../../api/AvailableBanksApi";
import { calculateOutstanding } from "../../helpers/functions";
import { useDispatch } from "react-redux";
import { filter, over } from "lodash";

type Props = {
    data: any,
    checkedItems: any,
    balance: number,
    showNumberInput: boolean,
    doPaymentAction?: (data: any) => void;
}

const PaymentModal = (props: Props) => {

    const dispatch = useDispatch();

    const { data, checkedItems, balance = 0.00, showNumberInput, doPaymentAction = (data: any) => { }, } = props;
    const [checked, setChecked] = useState(checkedItems);
    const [totalToPay, setTotalToPay] = useState(0);

    const toggleItem = (item: string) => {
        const isChecked = checked.includes(item);
        let items = [...checked];
        if (isChecked) {
            setChecked([...items.filter((i: string) => i !== item)]);
        } else {
            items.push(item);
            setChecked([...items]);
        }
    };

    useEffect(() => {
        calculateInvoicesToPay();
    }, [dispatch, checked])

    const calculateInvoicesToPay = () => {
        if (showNumberInput == false) {
            let items = [...checked];
            let checkedTotal = data
                .filter((x: any) => items.indexOf(x.batno as string) > -1)
                .map((y: any) => parseFloat(((calculateOutstanding(y)) as string).replace('£', '').replace(',', ''))) // Remove '£' and convert to number
                .reduce((acc: number, val: number) => acc + val, 0); // Sum up the amounts

            setTotalToPay(checkedTotal);
        }
    };

    const isToggled = (item: string) => {
        return checked.includes(item);
    };

    const doBlurAction = () => {
        if( totalToPay <= 0 ){ setTotalToPay(0) }
    };
    
    const triggerPayment = (amount: number, allocatedInvoices: string[], type: string) => {

        let out = [];
        let totalAmount = amount;

        for (let i = 0; i < allocatedInvoices.length; i++) {

            let inv = data.find((x: any) => x.batno == allocatedInvoices[i]);

            if (!inv) {
                continue;
            }

            let invAmount = parseFloat(calculateOutstanding(inv));
            let amountToPay = totalAmount > invAmount ? invAmount : totalAmount;

            totalAmount -= amountToPay;

            amountToPay = amountToPay < 0 ? 0 : amountToPay;

            out.push({
                invoice: allocatedInvoices[i],
                amount: amountToPay
            });
        }


        doPaymentAction(
            {
                amount: amount,
                invoice: out,
                type: type
            }
        );
    };

    const updateTotal = (val: string) => {
        let num = parseFloat(val);
        setTotalToPay(isNaN( num ) ? 0 : num);
    };
    let balanceAfterPayment = balance - totalToPay;
    balanceAfterPayment = balanceAfterPayment;

    const uniqueById = new Set(checkedItems).size;

    let invoiceTotal = 0;



    let overFlowTotal = 0;


    let uInv = [];


    if( totalToPay > 0 && checked && showNumberInput){

        let out = [];
      
        let totalAmount = totalToPay;
        for (let i = 0; i < checked.length; i++) {
            let inv = data.find((x: any) => x.batno == checked[i]);

            if (!inv) {
                continue;
            }

            let invAmount = parseFloat(calculateOutstanding(inv));
            let amountToPay = totalAmount > invAmount ? invAmount : totalAmount;
            totalAmount -= amountToPay;
            amountToPay = amountToPay < 0 ? 0 : amountToPay;

            out.push({
                invoice: inv.batno,
                amount: amountToPay
            });

            if(amountToPay > 0){
                uInv.push(inv.batno);   
            }
        }

        if( totalAmount == 0 ){
            overFlowTotal = 0;
        }else{
            overFlowTotal = totalAmount;
        }

        invoiceTotal = totalToPay - overFlowTotal;
        balanceAfterPayment -= totalAmount;
    }

    invoiceTotal = isNaN(invoiceTotal) ? 0 : invoiceTotal;
    overFlowTotal = isNaN(overFlowTotal) ? 0 : overFlowTotal;
    balanceAfterPayment = isNaN(balanceAfterPayment) ? 0 : balanceAfterPayment;


    const filteredData = data.filter( (x:any) => calculateOutstanding(x) != '0.00');

    const totalUnpaidInvoices = filteredData.length;

    filteredData.sort( (x:any) => checkedItems.indexOf( x.batno ) > -1);

    return (
        <div className="payment-modal">
            <div className="payment-modal__row">
                <div className="payment-modal__col--left">

                    <div>
                        <div className="payment-modal__title d-flex justify-content-start align-items-center">
                            <div className="payment-modal__icon">
                                {
                                    CreditCard()
                                }
                            </div>
                            <div className="payment-modal__title-text">
                                <span className="payment-modal__title-subtitle">
                                    Pay Invoices
                                </span>
                                <h3>Make Payment
                                </h3>
                            </div>
                        </div>
                        <div className="payment-modal__body">
                            <div className="payment-modal__body-text">

                                {showNumberInput &&
                                    <div className="mb-2">
                                        <label>Balance to Pay</label>
                                        <TextInput type="number" step={1} bgColor="grey" style="normal" value={ parseFloat(totalToPay  + "")} onChange={updateTotal} onBlur={ () => doBlurAction() } />
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="payment-modal__footer mt-2">

                            <div className="payment-section">
                                {!showNumberInput && <>
                                    <div className='payment-section__row border-top'>
                                        <div className='payment-section__row-title'>Selected Invoices</div>
                                        <div className='payment-section__row-value'>{uniqueById}</div>
                                    </div>
                                </>
                                }

                                {showNumberInput && <>

                                    <div className='payment-section__row border-top'>
                                        <div className='payment-section__row-title'>Payments Against Invoices</div>
                                        <div className='payment-section__row-value'>{uInv.length}</div>
                                    </div>
                                    <div className='payment-section__row border-top'>
                                        <div className='payment-section__row-title'>Allocate to Invoices</div>
                                        <div className='payment-section__row-value'>&pound;{invoiceTotal.toFixed(2)}</div>
                                    </div>
                                    <div className='payment-section__row border-top'>
                                        <div className='payment-section__row-title'>Unallocated Amount</div>
                                        <div className='payment-section__row-value'>&pound;{overFlowTotal.toFixed(2)}</div>
                                    </div>
                                    <div className='payment-section__row'>
                                        <div className='payment-section__row-title'>Total to Pay</div>
                                        <div className='payment-section__row-value'>&pound;{totalToPay.toFixed(2)}</div>
                                    </div>
                                </>}
                                {!showNumberInput && <>
                                    <div className='payment-section__row'>
                                        <div className='payment-section__row-title'>Total to Pay</div>
                                        <div className='payment-section__row-value'>&pound;{totalToPay.toFixed(2)}</div>
                                    </div>
                                </>
                                }
                            </div>

                            <div className="payment-section border-top">
                                <div className='payment-section__row'>
                                    <div className='payment-section__row-title'>Balance After Payment</div>
                                    <div className='payment-section__row-value'>&pound;{balanceAfterPayment.toFixed(2)} </div>
                                </div>
                            </div>
                            <div className="payment-button-outer text-right mt-3">
                                <Button disabled={totalToPay <= 0} text="Pay Now" size="md" type="orange" shape="chamfer" onClick={() => triggerPayment(totalToPay, checked, showNumberInput ? 'balance' : 'allocated')} ></Button>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="payment-modal__col--right">

                    <div className="payment-modal-right__title mb-2">Pay Invoices</div>
                    <div className="payment-modal-right__body">
                        {filteredData && filteredData.map((s: any, i: number) =>
                            <div className="payment-modal-right__body-row">
                                <div className="payment-modal-first-col">
                                    <div className='checkbox-col'>
                                        <span className={isToggled(s.batno as string) ? 'cursor fill-square checkbox' : 'checkbox cursor'} onClick={() => toggleItem(s.batno as string)}>

                                            {
                                                isToggled(s.batno as string) ?
                                                    CheckSquare() :
                                                    CheckSquareBlank()
                                            }
                                        </span>
                                    </div>
                                    <div>Invoice No . {s.batno}</div>
                                </div>
                                <div>£{calculateOutstanding(s)}</div>
                            </div>
                        )}
                    </div>
                    <div className="payment-modal-right__footer border-top mt-2">
                        <div>
                            Selected {checked.length} of { totalUnpaidInvoices } Invoices
                        </div>
                        <div className="text-orange">
                            Balance: &pound;{balance.toFixed(2)}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PaymentModal;
