import React, { useRef, useState } from 'react';
import './ProductCardEx.scss';
import { GrDown, GrUp } from 'react-icons/gr';
import TextInput from '../TextInput';
import { Product } from '../../types/Product';
import ProductImage from '../ProductImage';
import { Price } from '../../types/Price';
import { Pallet, Stock } from '../../types/Stock';
import { ProductType } from '../../types/ProductType';
import _ from 'lodash';
import Button from '../Button';
import { OrderLine } from '../../types/Order';
import { checkUnitPrice, generateUID, getCustomerID, lwRoundNumber, renderCurrentLength, roundInteger, roundNumber } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { addToBasket } from '../../actions/Order';
import BoxSvg from '../../assets/svgs/box-SVG.svg';
import MultiChoice from '../MultiChoice';
import { emailNotifyStock } from '../../actions/Email';
import { getCustomer } from '../../selectors/Customer';
import { isSendingEmail } from '../../selectors/Email';
import TooltipLabel from '../TooltipLabel';
import ExpectedStockDate from '../ExpectedStockDate';
import ProductImageList from '../ProductImageList';
import CONSTANTS from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import TooltipViewer from '../TooltipViewer';
import { config } from '../../config/config';
import dateFormat from 'date-fns/format';
import { fetchStock } from '../../actions/Stock';

type Props = {
    product?: Product | null;
    price?: Price | null;
    stock?: Stock | null;
    productType?: ProductType | null,
    similar?: Array<Product> | null;
    onClick?: (arg: string) => void;
    onPopup?: (arg: string) => void;
};

const ProductCardEx = (props: Props) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const { product, price, stock, productType, similar } = props;
    const dispatch = useDispatch();
    const customer = useSelector(getCustomer);
    const sendingEmail = useSelector(isSendingEmail);
    const [togglePallet, setTogglePallet] = useState(false);
    const [currentPacks, setCurrentPacks] = useState(0);
    const [currentSQMPacks, setCurrentSQMPacksInner] = useState(0);
    const [currentPallet, setCurrentPallet] = useState<Pallet[]>([]);

    const [reference, setReference] = useState('');
    const [referencePallets, setReferencePallets] = useState('');
    const [orderLines, setOrderLines] = useState<OrderLine[]>([]);
    const [emailSent, setEmailSent] = useState(false);
    const [toggleMore, setToggleMore] = useState<boolean>(false);
    const palletStockMax = 12;
    const history = useHistory();
    const ref = useRef(null);



    const palletTotal = currentPallet ? _.sum(_.map([...currentPallet], (x) => { return (x.pallet_prc) })) : 0;


    const onNotify = async () => {
        await dispatch(emailNotifyStock({
            customerName: customer?.NAME || 'N/A',
            customerID: getCustomerID() || 'N/A',
            customerEmail: customer?.contact_email || 'N/A',
            productQuality: product?.quality_full_name || 'N/A',
            productColor: product?.colour_full_name || 'N/A',
            productSKU: product?.product_sku || 'N/A',
            rep_email: customer?.rep_email || 'N/A',
            rep_name: customer?.rep_name || 'N/A',
            branchEmail: customer?.branch_email || ''
        }));
        setEmailSent(true);
        setTimeout(() => {
            setEmailSent(false);
        }, 10000);
    };



    const checkPalletPrice = (): number => {
        let x = price?.pallet_price ? price.pallet_price : 0;

        if (!stock || !stock.pallet) {
            return x;
        }

        let priceCount = stock?.pallet?.filter(y => y.pallet_prc && y.pallet_prc < x);

        if (priceCount.length == stock.pallet.length && stock.pallet.length > 0) {
            return stock.pallet[0].pallet_prc ? stock.pallet[0].pallet_prc : x;
        }

        return x;
    };


    const getCurrentPalletQty = () => {
        if (currentPallet == null) {
            return 0;
        }

        let total = 0;
        currentPallet.map(x => {
            total += x.pallet_qty;
        });
        return total;
    };

    const setCurrentSQMPacks = (val: string) => {

        let num = parseFloat(val);
        num = isNaN( num ) ? 0 : num;
        num = num < 0 ? 0 : num;

        // @ts-ignore
        setCurrentSQMPacksInner(num);
        // @ts-ignore
        setCurrentPacks(Math.ceil(lwRoundNumber(num / product?.sqm_per_box)));
    };

    const setCurrentPacksSQM = (val: string) => {
        let num = parseInt(val);
        num = isNaN( num ) ? 0 : num;
        num = num < 0 ? 0 : num;

        // @ts-ignore
        setCurrentSQMPacksInner(lwRoundNumber(num * product?.sqm_per_box));
        // @ts-ignore
        setCurrentPacks(num);
    };


    const onAddPackToOrderSummary = () => {
        const localOrderLines = [...orderLines];
        if (product && price && productType && currentPacks) {
            let isPresold = currentPacks > getNonPresoldStock();
            let estimated = getPalletEstimatedDeliveryDate();
            let pallet_id = isPresold ? getPalletEstimatedId() : '';

            localOrderLines.push({
                uid: generateUID(product.product_sku, product.quality_full_name, reference),
                product_sku: product.product_sku,
                product_quality: product.quality_full_name,
                product_type_code: product.product_type_code,
                product_type_code_name: productType.product_type_desc,
                quantity: currentPacks,
                half_roll: '',
                pallet_id: pallet_id,
                roll_id: '',
                length: 0,
                width: 0,
                item_price: unitPrice,
                line_reference: reference,
                line_price: unitPrice * currentPacks,
                line_discount: price.cut_price_discount,
                colour_full_name: product.colour_full_name,
                price_per_sqm: price.unit_price_per_sqm,
                line_delivery_date: '',
                line_availability: getPackDate(),
                collection: false,
                carriage_charge_linit: price.carriage_charge_linit,
                carriage_charge_units: price.carriage_charge_units,
                carriage_charge_coir: price.carriage_charge_coir,
                presold: isPresold,
                cart_date: estimated != 'TBA' && estimated != '' ? 'Earliest Estimated ' + estimated : estimated,
            });
            //setReference('');
            setCurrentSQMPacks('');
            setOrderLines(localOrderLines);
        }
    };

    const onAddPalletToOrderSummary = () => {
        const localOrderLines = [...orderLines];
        if (product && price && productType && currentPallet) {
            let i = 1;
            currentPallet?.forEach(x => {

                let dt = new Date(x.pallet_expected_stock_date || '2022-01-01');
                let dDate = x.pallet_expected_stock_date != null && x.pallet_batchno == 'PRESOLD' ? 'Earliest Estimated ' + dateFormat(dt, 'dd.MM.yyy') : '';
                if (dDate == '2049-12-25') {
                    dDate = 'TBA';
                }

                if (x.pallet_batchno != 'PRESOLD') {
                    dDate = '';
                }


                localOrderLines.push({
                    uid: generateUID(product.product_sku, product.quality_full_name, referencePallets + ' ' + i,),
                    product_sku: product.product_sku,
                    quantity: x?.pallet_qty,
                    length: 0,
                    width: 0,
                    half_roll: '',
                    pallet_id: x?.pallet_id,
                    roll_id: '',
                    product_quality: product.quality_full_name,
                    product_type_code: product.product_type_code,
                    product_type_code_name: productType.product_type_desc,
                    item_price: (x.pallet_prc ? x.pallet_prc : price.pallet_price),
                    line_reference: referencePallets + ' ' + i,
                    line_price: (x.pallet_prc ? x.pallet_prc : price.pallet_price) * x.pallet_qty,
                    colour_full_name: product.colour_full_name,
                    line_discount: price.roll_price_discount,
                    line_availability: x.pallet_availability,
                    line_delivery_date: '',
                    collection: false,
                    price_per_sqm: price.pallet_price_per_sqm,
                    carriage_charge_linit: price.carriage_charge_linit,
                    carriage_charge_units: price.carriage_charge_units,
                    carriage_charge_coir: price.carriage_charge_coir,
                    presold: x.pallet_batchno == 'PRESOLD',
                    cart_date: dDate,
                });
                i++;
            });
            setOrderLines(localOrderLines);
            //setReferencePallets('');
            setCurrentPallet([]);
            if (scrollRef && scrollRef.current) {
                (scrollRef.current as any).scrollIntoView();
            }
        }
    };

    const addSelectedPallet = (pallet: Pallet) => {
        let pallets = currentPallet != null ? currentPallet : [];

        //Selected roll empty so just push our roll
        if (null == currentPallet) {
            setCurrentPallet([pallet]);
            return;
        }

        let spliced = false;
        if (currentPallet && currentPallet.length > 0) {

            //Check if we already added
            spliced = currentPallet.includes(pallet);

            if (spliced) {
                setCurrentPallet(pallets.filter(x => {
                    return x.pallet_id != pallet.pallet_id;
                }));
                return;
            }
        }

        const localPallets = [...currentPallet];
        localPallets.push(pallet);
        setCurrentPallet(localPallets);
    };

    let totalQty = stock?.pallet?.find(p => p.pallet_batchno.indexOf('TOTAL') == 0)?.pallet_qty || 0;

    if (config.enablePresold) {
        stock?.pallet?.map(x => {
            if (x.pallet_batchno == 'PRESOLD') {
                totalQty += x.pallet_qty;
            }
        });
    }



    const getPalletStatus = () => {
        let x = palletStock.filter(x => x.pallet_id != '' && x.pallet_batchno != 'PRESOLD').length;

        if (x > 0) {
            return 'In Stock';
        }

        if (config.enablePresold) {
            let y = palletStock.filter(z => z.pallet_batchno == 'PRESOLD').length;

            if (y > 0 && config.enablePresold) {
                return 'Pre-Order';
            }
        }

        return 'Out of Stock';
    };


    const hasPackStock = () => {
        let x = stock?.pallet?.filter(p => p.pallet_batchno.indexOf('TOTAL') == 0).length;
        if (x && x > 0) {
            return 'In Stock';
        }

        if (config.enablePresold) {
            let y = palletStock.filter(z => z.pallet_batchno == 'PRESOLD').length;

            if (y > 0 && config.enablePresold) {
                return 'Pre-Order';
            }
        }

        return 'Out of Stock';
    };


    const onAddOrderSummaryToBasketAndContinue = () => {
        dispatch(addToBasket(orderLines, customer?.delivery_address_code, false, false));

        const el2 = ref.current as any;
        const styles = getComputedStyle(el2);
        const z = parseInt(styles.zIndex);

        if (z == 2) {
            history.push(CONSTANTS.APP_ROUTES.CART);
        } else {
            if (null != props.onPopup) {
                props.onPopup('You have added ' + orderLines.length + ' products to your basket');
            }
        }
        //Clear the summary 
        setOrderLines([]);
        setTimeout(() => {
            dispatch(fetchStock(product ? product.product_sku : ''));
        }, 1000);
    };

    const scrollToPallets = () => {
        const palletDiv = document.getElementById('pallets');
        if (null != palletDiv) {
            setTogglePallet(true);
            palletDiv.scrollIntoView();
        }
    };



    const getLocalUsedPacks = () => {
        let total = 0;
        orderLines.filter(o => o.product_sku === product?.product_sku).forEach((cur) => total += cur.quantity);
        return total;
    };


    const onMinus = (index: number) => {
        let localOrderLines = [...orderLines];
        localOrderLines = localOrderLines.filter((or, i) => i !== index);
        setOrderLines(localOrderLines);
    };

    const getUsedPallets = () => {
        const localOrderLines = [...orderLines];
        let usedIds: any = [];
        localOrderLines.map(x => {
            if (x.pallet_id != '' && x.pallet_id.indexOf('TOTAL') === -1) {
                usedIds.push(x.pallet_id);
            }
        });
        return usedIds;
    };

    const usedPallets = getUsedPallets();

    const palletStock = !config.enablePresold ? (stock?.pallet?.filter(p => p.pallet_batchno.indexOf('TOTAL') === -1 && p.pallet_batchno != 'PRESOLD' && !usedPallets.includes(p.pallet_id)) || []) : (stock?.pallet?.filter(p => p.pallet_batchno.indexOf('TOTAL') === -1 && !usedPallets.includes(p.pallet_id)) || []);


    const [activeTooltip, setActiveTooltip] = useState<string>('');
    const toggleToolTip = (name: string, direction: boolean) => {

        if (!direction) {
            setActiveTooltip('');
            return;
        }
        setActiveTooltip(name);
    };

    const getToggleState = (name: string) => {
        return name == activeTooltip;
    };

    const removeTooltip = () => {
        setActiveTooltip('');
    };


    const hasPresoldStock = () => {
        if (!config.enablePresold) {
            return false;
        }
        let presold = stock?.pallet?.filter(p => p.pallet_batchno == 'PRESOLD');
        return presold && presold.length > 0;
    };

    /* Get the expected stock date from the stock */
    const getExpectedDate = (theStock: any) => {
        if (!theStock) {
            return null;
        }

        let presoldRolls = theStock.pallet && theStock.pallet.filter((x: any) => x.pallet_batch_no == 'PRESOLD');

        if (null == presoldRolls || presoldRolls.length == 0) {
            if (theStock.stock_expected_date && theStock.stock_expected_date != '2049-12-25') {
                return theStock.stock_expected_date;
            }
            return null;
        }

        return presoldRolls[0].pallet_expected_stock_date;
    };


    const getLongestPallet = () => {

        let longest = 0;
        let filteredPallets = stock?.pallet?.filter(y => y.pallet_batchno.indexOf('TOTAL') === -1);

        if (filteredPallets == null) {
            let stockTotal = stock?.pallet?.filter(y => y.pallet_batchno.indexOf('TOTAL') == 0);
            if (stockTotal == null) {
                return 0;
            }
            return stockTotal[0].pallet_qty;
        }

        filteredPallets.map(x => {
            if (x.pallet_qty > longest) {
                longest = x.pallet_qty;
            }
        });
        return longest;
    };

    const getTotalPresold = () => {
        let total = 0;
        stock?.pallet?.map(x => {
            if (x.pallet_batchno == 'PRESOLD') {
                total += x.pallet_qty;
            }
        });
        return total;
    };

    const checkExceedsPallet = () => {
        let lp = getLongestPallet();
        return currentPacks > lp && currentPacks > 0 && lp > 0;
    };

    const checkIsAPallet = () => {
        let lp = getLongestPallet();
        return currentPacks == lp && currentPacks > 0;
    };


    //Calculate the remaining after a subtraction -- can't be negative 
    const calculateRemaning = (inNumber: number) => {
        let x = inNumber < 0 ? 0 : inNumber;
        let tmp = ('' + x).split('.')
        if (tmp.length > 1) {
            let decimal = parseFloat('0.' + tmp[1]).toFixed(1);
            return parseFloat(tmp[0]) + decimal;
        }
        return x;
    };

    const totalUsedPresold = () => {
        let total = 0;
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku && c.presold == true) {
                total += c.quantity;
            }
        });
        return total;
    };

    //Work out how many packs we have selected in total
    const workOutCutPieces = () => {
        let lengthList: any = [];
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku) {
                lengthList.push(c.quantity);
            }
        });
        return lengthList;
    };


    const getPackDate = () => {
        //Search the rolls for the current width
        let x = stock?.pallet?.filter(y => y.pallet_batchno != 'PRESOLD');

        if (x == null || x.length == 0) {
            return;
        }
        return x[0].pallet_availability;
    };

    //Get the estimated delivery date for a cut 
    const getPalletEstimatedDeliveryDate = (): string => {

        if (!config.enablePresold) {
            return '';
        }

        //Search the rolls for the current width
        let x = stock?.pallet;

        if (null == x) {
            return 'TBA';
        }

        //let total  
        let total = currentPacks;
        let counter = 0;


        //How many presold rolls are we cutting into ? 
        let cutPieces = workOutCutPieces();

        let theRemainingPieces: any = [];

        x.forEach(y => {
            theRemainingPieces.push({ ...y });
        });

        //Go over the cut pieces and assign them to a roll
        cutPieces.map((piece: any) => {
            //Loop over the rolls 
            for (let i = 0; i < theRemainingPieces.length; i++) {
                if (theRemainingPieces[i] - piece > 0) {
                    theRemainingPieces[i].pallet_qty -= piece;
                    break;
                }
            }
        });


        for (let i = 0; i < theRemainingPieces.length; i++) {
            if (theRemainingPieces[i].pallet_qty >= total) {
                //This one is the date we need
                if (theRemainingPieces[i].pallet_batchno == 'PRESOLD') {
                    let dt = new Date(theRemainingPieces[i].pallet_expected_stock_date || '2022-01-01');
                    let dDate = theRemainingPieces[i].pallet_expected_stock_date != null ? dateFormat(dt, 'dd.MM.yyy') : '';
                    if (dDate == '2049-12-25') {
                        dDate = 'TBA';
                    }
                    return dDate;
                }
                return stock && stock.delivery_date.length > 0 ? dateFormat(new Date(stock.delivery_date[0].delivery_date), 'dd.MM.yyy') : 'TBA';
            }
        }
        return 'TBA';
    };

    const getPalletEstimatedId = (): string => {

        if (!config.enablePresold) {
            return '';
        }

        //Search the rolls for the current width
        let x = stock?.pallet;

        if (null == x) {
            return '';
        }

        //let total  
        let total = currentPacks;
        let counter = 0;


        //How many presold rolls are we cutting into ? 
        let cutPieces = workOutCutPieces();

        let theRemainingPieces: any = [];

        x.forEach(y => {
            theRemainingPieces.push({ ...y });
        });

        //Go over the cut pieces and assign them to a roll
        cutPieces.map((piece: any) => {
            //Loop over the rolls 
            for (let i = 0; i < theRemainingPieces.length; i++) {
                if (theRemainingPieces[i] - piece > 0) {
                    theRemainingPieces[i].pallet_qty -= piece;
                    break;
                }
            }
        });


        for (let i = 0; i < theRemainingPieces.length; i++) {
            if (theRemainingPieces[i].pallet_qty >= total) {
                //This one is the date we need
                if (theRemainingPieces[i].pallet_batchno == 'PRESOLD') {
                    return theRemainingPieces[i].pallet_id;
                }
                return '';
            }
        }
        return '';
    };


    const getNonPresoldStock = () => {
        let total = 0;
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku && c.presold == false) {
                total += c.quantity;
            }
        });

        let inStockTotal = stock?.pallet?.filter(y => y.pallet_batchno != 'PRESOLD' && y.pallet_batchno.indexOf('TOTAL') == 0);

        if (!inStockTotal) {
            return 0;
        }

        let totalQty = 0;
        inStockTotal.map(x => {
            totalQty += x.pallet_qty;
        });

        return totalQty - total;
    };


    const checkExeedsTotal = () => {
        let total = 0;
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku) {
                total += c.quantity;
            }
        });

        let inStockTotal = stock?.pallet?.filter(y => y.pallet_batchno != 'PRESOLD' && y.pallet_batchno.indexOf('TOTAL') == 0);

        if (!inStockTotal) {
            return true;
        }

        let totalQty = 0;
        inStockTotal.map(x => {
            totalQty += x.pallet_qty;
        });

        return ((currentPacks) > totalQty);
    };



    const getExceedsAvailable = () => {
        let total = 0;

        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku) {
                total += c.quantity;
            }
        });

        let inStockTotal = stock?.pallet?.filter(x => x.pallet_batchno.indexOf('TOTAL') == 0 || x.pallet_batchno == 'PRESOLD');

        if (!inStockTotal) {
            return true;
        }

        let totalQty = 0;
        inStockTotal.map(x => {
            totalQty += x.pallet_qty;
        });


        return ((total + currentPacks) > totalQty);
    };

    const getAllStock = () => {
        return getNonPresoldStock() + getTotalPresold();
    };

    const exceedsPallet = checkExceedsPallet() && config.enablePresold;
    const isAPallet = checkIsAPallet() && config.enablePresold;
    const exceedsAvailableStock = getExceedsAvailable();
    const showPredorderNotice = checkExeedsTotal() && currentPacks > 0 && getAllStock() > 0;
    const totalPresold = getTotalPresold();


    let ordersummaryText = "Add To Order Summary";
    if (showPredorderNotice) {
        ordersummaryText = "Add Pre-Order to Summary";
    }

    const disableAddPacks = !currentSQMPacks || reference.length <= 0 || exceedsAvailableStock;

    const unitPrice = checkUnitPrice(currentPacks, price);

    return (
        <div className="product-card-ex" onClick={removeTooltip}>
            <div className='row'>
                <div className='col-md-6 product-card-image hide-mob'>
                    <ProductImage className={'product__image'} imageCode={product?.IMAGE_code} skuCode={product?.product_sku} imageAlt={product?.colour_full_name} size='large' />
                </div>
                <div className='col-md-6'>

                    <div className="product-card-ex__header">
                        <div className="product-card-ex__header__img">
                            <div className="product-card-ex__header__img__name">
                                <p className="product-card-ex__header__img__name__top">
                                    {product?.quality_full_name.toLocaleLowerCase()} {product?.colour_full_name.toLowerCase()}
                                </p>
                                <p className="product-card-ex__header__img__name__bottom small text-grey">
                                    {_.startCase(_.toLower(productType?.product_type_desc.toLowerCase()))}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="product-card-ex__pricing product-card__pricing">
                        <h3>Pricing &amp; Availability</h3>
                        <div className="product-card__availability_row">
                            {config.enablePacks == true &&
                                <div>
                                    <div className='product-card-ex__pricing__header product-card__pricing__header'>
                                        Available Packs
                                    </div>
                                    <div className="product-card__pricing__content">
                                        <div className="product-card__pricing__prices">
                                            <div className='product-card-ex__pricing__price'>
                                                Price Per Pack: &pound; {unitPrice.toFixed(2) || 0}
                                            </div>
                                            <div className='product-card-ex__pricing__price'>
                                                Price Per SQM: &pound; {price?.unit_price_per_sqm.toFixed(2) || 0}
                                            </div>
                                        </div>
                                        <div className='product-card-ex__pricing__availability product-card__pricing__availability'>
                                            <div className='d-flex justify-content-end align-items-center'>

                                                {(hasPackStock() == 'In Stock') &&
                                                    <span className='badge badge-pill'>In Stock</span>
                                                }
                                                {((hasPackStock() == 'Out of Stock') && hasPresoldStock() == false) &&
                                                    <>
                                                        <span className='badge badge-pill out-of-stock'>Out of Stock</span>
                                                        <TooltipViewer onClick={(direction: boolean) => toggleToolTip('cut', direction)} toggleState={getToggleState('cut')} side='right' tipTitle='Out of Stock' tipText='Please contact our Customer Service Team for more information on expected arrival of new stock'></TooltipViewer>
                                                    </>
                                                }
                                                {stock?.stock_availablity != 'yes' && hasPresoldStock() && <>
                                                    <span className='badge badge-pill pre-order'>Pre-Order</span>
                                                    <TooltipViewer onClick={(direction: boolean) => toggleToolTip('cut', direction)} toggleState={getToggleState('cut')} side='right' tipTitle='Pre Order' tipText='We are currently awaiting stock to arrive from the manufacturers. Your order will be despatched shortly after the date displayed'></TooltipViewer></>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                    {stock?.stock_availablity != 'yes' &&
                                        <div className='product-card__pricing__view_availability'>
                                            <div className='product-card__pricing__availability'>
                                                <div className='stock-date'>
                                                    <ExpectedStockDate stock={getExpectedDate(stock)} showPipe={false} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {product?.product_type_code != 'Q' &&
                                <div>
                                    <div className='product-card-ex__pricing__header product-card__pricing__header'>
                                        Available Pallets
                                    </div>
                                    <div className="product-card__pricing__content">
                                        <div className="product-card__pricing__prices">
                                            <div className='product-card-ex__pricing__price'>
                                                Price Per Pack: &pound; {checkPalletPrice().toFixed(2) || 0.00}
                                            </div>
                                            <div className='product-card-ex__pricing__price'>
                                                Price Per SQM: &pound; {price?.pallet_price_per_sqm.toFixed(2) || 0.00}
                                            </div>
                                        </div>
                                        <div className="product-card-ex__pricing__availability product-card__pricing__availability">
                                            <div className='d-flex justify-content-end align-items-center stock-availability-justification'>
                                                <div>
                                                    {(getPalletStatus() == 'In Stock') &&
                                                        <span className='badge badge-pill'>In Stock</span>
                                                    }
                                                    {((getPalletStatus() == 'Out of Stock') && hasPresoldStock() == false) &&
                                                        <>
                                                            <span className='badge badge-pill out-of-stock'>Out of Stock</span>
                                                            <TooltipViewer onClick={(direction: boolean) => toggleToolTip('pallet', direction)} toggleState={getToggleState('pallet')} side='right' tipTitle='Out of Stock' tipText='Please contact our Customer Service Team for more information on expected arrival of new stock'></TooltipViewer>
                                                        </>
                                                    }
                                                    {((getPalletStatus() == 'Pre-Order') && hasPresoldStock()) && <>
                                                        <span className='badge badge-pill pre-order'>Pre-Order</span>
                                                        <TooltipViewer onClick={(direction: boolean) => toggleToolTip('pallet', direction)} toggleState={getToggleState('pallet')} side='right' tipTitle='Pre Order' tipText='We are currently awaiting stock to arrive from the manufacturers. Your order will be despatched shortly after the date displayed'></TooltipViewer></>
                                                    }
                                                </div>
                                                {
                                                    config.showAvailableRollsInline == true && <>
                                                        <a className="px-2 py-1 hide-mob" onClick={scrollToPallets}>View Available Pallets</a>

                                                    </>
                                                }

                                            </div>
                                        </div>

                                    </div>

                                    <div className="product-card__pricing__view_availability">
                                        {config.showAvailableRollsInline != true && <>
                                            <a className='px-2 py1 hide-mob' onClick={scrollToPallets}>View Available Pallets</a>
                                        </>}

                                        {
                                            /*stock?.stock_availablity != 'yes' &&
                                                <div className='product-card-ex__pricing__availability product-card__pricing__availability'>
                                                    <div className="d-flex justify-content-end stock-date">
                                                        <ExpectedStockDate stock={getExpectedDate(stock?.stock_expected_date)} showPipe={false} />
                                                    </div>
                                                </div>
                                                */
                                        }
                                    </div>
                                    <div className='product-card-image hide-desktop'>
                                        <ProductImage className={'product__image'} imageCode={product?.IMAGE_code} skuCode={product?.product_sku} imageAlt={product?.colour_full_name} size='large' />
                                    </div>


                                </div>
                            }
                        </div>
                        <div className='product-card-ex__colours'>
                            <ProductImageList similar={similar} current={product} onClick={props.onClick} />
                        </div>
                    </div>


                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>

                    {/*<!-- PACKS -->*/}
                    {config.enablePacks == true &&
                        <div className="product-card-ex__accordion mt-3">
                            <div className="product-card-ex__accordion__header">
                                <div className="product-card-ex__accordion__header__title">
                                    Packs
                                    <div className="font-small">1 Pack = {product?.sqm_per_box} SQM</div>
                                </div>

                                <div className="product-card__price__right">
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <div className="product-card__price__right__details">
                                            <span className="text-small white">
                                                {hasPackStock()}
                                            </span>
                                        </div>

                                        {hasPackStock() == 'In Stock' && <div className="green-circle" />}
                                        {hasPackStock() == 'Pre-Order' && <div className="amber-circle" />}
                                        {hasPackStock() == 'Out of Stock' && <div className="red-circle" />}

                                    </div>
                                </div>
                            </div>
                            {
                                <div className="product-card-ex__accordion__body">
                                    <div className='inner'>

                                        {(stock?.stock_availablity === 'no' && getPalletStatus() == 'Out of Stock') && <div className="row mb-4">
                                            {!emailSent && <Button disabled={sendingEmail} type="link" text="Notify When Back In Stock" onClick={onNotify} />}
                                            {emailSent && <div className="alert alert-success" role="alert">We have registered your interest!</div>}
                                        </div>}

                                        {(stock?.stock_availablity === 'yes' || getPalletStatus() != 'Out of Stock') && <div className="row align-items-end">

                                            <div className="col-md-7">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className='d-flex justify-content-between align-items-end calculation'>
                                                            <div className='calculation__left'>
                                                                <TooltipLabel text="SQM Required" tipText="Calculates number of packs to order" />
                                                                <div className="mt-2">
                                                                    <TextInput type="number" step={0.01} bgColor="white" onChange={setCurrentSQMPacks} value={renderCurrentLength(currentSQMPacks)} />
                                                                </div>
                                                            </div>
                                                            <div className='calculation__mid'></div>
                                                            <div className='calculation__right'>
                                                                <TooltipLabel text="Packs" tipText="Based on your SQM to nearest full pack" />
                                                                <div className="mt-2">
                                                                    <TextInput type="number" step={1} bgColor="white" value={roundInteger(currentPacks)} onChange={setCurrentPacksSQM} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-5'>
                                                <div className='mobile-cut-summary'>
                                                    <div><span className='small text-grey calculation--label'>Total Area</span><span className='cut-area'>{renderCurrentLength(currentSQMPacks)} m<sup>2</sup></span></div>
                                                    <div><span className='small text-grey calculation--label'>{config.showAvailableRollsInline != true ? 'Subtotal:' : 'Total:'}</span><span className='cut-subtotal text-orange'>£{roundNumber((currentPacks || 0) * (price?.unit_price || 0))}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        }


                                        {(exceedsPallet == true && false === exceedsAvailableStock) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                            Your order exceeds our standard pallet quantity of {getLongestPallet()}. Please select a full pallet from the pallet section below and select the balance separately.
                                        </div>
                                        }

                                        {(isAPallet == true && false === exceedsAvailableStock) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                            Your order quantity equals a full pallet. Please place your order using the pallet section below
                                        </div>
                                        }


                                        {/*(exceedsAvailableStock === true && getAllStock() > 0) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                        Max available packs: {getAllStock() }
                                    </div>
                                    */}

                               

                                        {(getAllStock() == 0 && currentPacks > 0) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                            There are no available packs. We are currently unable to fulfil this request.
                                        </div>
                                        }



                                        {(exceedsAvailableStock && (stock?.stock_availablity === 'yes' || getPalletStatus() != 'Out of Stock')) && <div>
                                            {/* @ts-ignore */}
                                            {currentPacks > (totalQty - getLocalUsedPacks()) && <div className="mb-3 mt-3 alert alert-danger" role="alert">Max available packs: {totalQty - getLocalUsedPacks()}</div>}
                                        </div>}

                                        {
                                            (showPredorderNotice && !exceedsAvailableStock && config.enablePresold) && <div className="mb-3 mt-3 alert alert-warning" role="alert">{
                                                <span className='pre-order-stock'>Pre-order stock available: {calculateRemaning(totalPresold - totalUsedPresold())} packs. Earliest estimated delivery date: {getPalletEstimatedDeliveryDate()}. </span>
                                            }
                                            </div>
                                        }


                                        {(stock?.stock_availablity === 'yes' || getPalletStatus() != 'Out of Stock') && <div className="row mt-5">
                                            <TextInput bgColor="white" placeholder="Example: Bedroom" required label="Reference" value={reference} onChange={setReference} />
                                        </div>}
                                        {(stock?.stock_availablity === 'yes' || getPalletStatus() != 'Out of Stock') && <div className="row mt-5">
                                            {/* @ts-ignore */}
                                            <Button text={ordersummaryText} disabled={disableAddPacks || isAPallet || exceedsPallet || getAllStock() == 0} onClick={onAddPackToOrderSummary} />
                                        </div>}
                                    </div>
                                </div>}
                        </div>
                    }
                    {/*<!-- PALLETS -->*/}
                    {product?.product_type_code != 'Q' &&
                        <div className="product-card-ex__accordion mt-3 pallets" id="pallets">
                            <div className="product-card-ex__accordion__header align-items-center">
                                <div className="product-card-ex__accordion__header__title">
                                    Pallets
                                </div>

                                <div className="stock_toggle__header__display">
                                    <div className="product-card__price__right">
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <div className="product-card__price__right__details">
                                                <span className="text-small white">
                                                    {getPalletStatus()}
                                                </span>
                                            </div>
                                            {getPalletStatus() == 'In Stock' && <div className="green-circle" />}
                                            {getPalletStatus() == 'Pre-Order' && <div className="amber-circle" />}
                                            {getPalletStatus() == 'Out of Stock' && <div className="red-circle" />}
                                        </div>
                                    </div>

                                    <div onClick={() => setTogglePallet(!togglePallet)} className="product-card-ex__accordion__header__toggle">
                                        {!togglePallet && <GrDown className="product-card-ex__caret" />}
                                        {togglePallet && <GrUp className="product-card-ex__caret" />}
                                    </div>
                                </div>
                            </div>

                            {togglePallet && <div className="product-card-ex__accordion__body">

                                {palletStock.length > 0 &&
                                    <div className="rolls-table">
                                        <div className='inner'>
                                            <div className='table--labels'>
                                                <div className='table--labels__title table--labels__title__option'><span className='grey-text'></span></div>
                                                <div className='table--labels__title table--labels__title__batch'><span className='grey-text'>Pallet ID:</span></div>
                                                <div className='table--labels__title table--labels__title__width'><span className='grey-text'>Qty:</span></div>
                                                <div className='table--labels__title table--labels__title__cost'><span className='grey-text'>Price Per Pack</span></div>
                                                <div className='table--labels__title table--labels__title__delivery'><span className='grey-text'>Delivery:</span></div>
                                                <div className='table--labels__title table--labels__title__stock'><span className='grey-text'>Stock:</span></div>
                                            </div>
                                        </div>
                                        <div className='table-contents'>
                                            {product && palletStock.slice(0, 4).map(r =>
                                                <MultiChoice.Pallets
                                                    onClick={() => addSelectedPallet(r)}
                                                    key={r.pallet_id}
                                                    price={r.pallet_prc ? r.pallet_prc : (price?.pallet_price || 0)}
                                                    palletId={r.pallet_id}
                                                    palletQty={r.pallet_qty}
                                                    delivery={r.pallet_availability}
                                                    selected={(currentPallet != null && currentPallet?.includes(r))}
                                                    inStock={palletStock.length > 0}
                                                    expected={r?.pallet_expected_stock_date}
                                                    stock={r.pallet_batchno}
                                                    poDate={r.pallet_expected_stock_date}
                                                />,
                                            )}

                                            {palletStock.length > 4 && toggleMore && product && palletStock.slice(4, palletStockMax).map(r =>
                                                <MultiChoice.Pallets
                                                    onClick={() => addSelectedPallet(r)}
                                                    key={r.pallet_id}
                                                    price={r.pallet_prc ? r.pallet_prc : (price?.pallet_price || 0)}
                                                    palletId={r.pallet_id}
                                                    palletQty={r.pallet_qty}
                                                    delivery={r.pallet_availability}
                                                    selected={(currentPallet != null && currentPallet?.includes(r))}
                                                    inStock={palletStock.length > 0}
                                                    expected={r?.pallet_expected_stock_date}
                                                    stock={r.pallet_batchno}
                                                    poDate={r.pallet_expected_stock_date}
                                                />,
                                            )}

                                            {palletStock.length > 4 &&
                                                <p className="text-orange cursor-pointer text-center text-small mt-3" onClick={() => setToggleMore(!toggleMore)}>View {toggleMore ? 'Less' : 'More'}</p>
                                            }

                                        </div>

                                    </div>}

                                {palletStock.length <= 0 &&
                                    <div className="no-pallets alert alert-danger text-center ps-3 mt-3">
                                        No Available Pallets
                                    </div>}

                                <div className='inner'>

                                    {
                                        config.showAvailableRollsInline &&
                                        <div className='mobile-cut-summary mt-4'>
                                            <div><span className='small text-grey calculation--label'>Total</span><span className='cut-subtotal text-orange'>£{roundNumber(palletTotal)}</span></div>
                                        </div>
                                    }

                                    {(stock?.stock_availablity === 'yes' || getPalletStatus() != 'Out of Stock') && <div className="row mt-5">
                                        <TextInput placeholder="Example: Bedroom" bgColor="white" label="Reference" required value={referencePallets} onChange={setReferencePallets} />
                                    </div>}

                                    {(stock?.stock_availablity === 'yes' || getPalletStatus() != 'Out of Stock') && <div className="row mt-5">
                                        <Button text="Add To Order Summary" disabled={!currentPallet || referencePallets.length <= 0} onClick={onAddPalletToOrderSummary} />
                                    </div>}
                                </div>
                            </div>}
                        </div>
                    }
                </div>
                <div className='col-md-6'>

                    {/*<!-- ORDER SUMMARY -->*/}
                    <div className="product-card-ex__accordion mt-3" ref={scrollRef}>
                        <div className="product-card-ex__accordion__header">
                            <div className="product-card-ex__accordion__header__title">
                                Order Summary
                            </div>
                        </div>
                        <div className="product-card-ex__accordion__body">
                            <div className='inner'>

                                {orderLines.map((o, i) => <div key={i} className="mt-2 mb-4 product-card-ex__summary__row">
                                    <div onClick={() => onMinus(i)} className="text-grey remove-action">
                                        &times;
                                    </div>
                                    <div className='product-card__summary__status'>
                                        {
                                            o.presold && <span className="text-grey text-small"><span className="amber-circle" ></span><span>Pre-Order</span></span>
                                        }
                                        {
                                            !o.presold && <span className="text-grey text-small hide-desktop"><span className="green-circle" ></span><span>In Stock</span></span>
                                        }
                                    </div>
                                    <div className='product-card-ex__summary__col product-card__summary__col__title'>
                                        <span className='product-card-ex__summary__col__title__name'>
                                            {o.product_quality.toLowerCase()}
                                        </span>
                                        <span className='product-card__summary__col__colour'>
                                            <span className='text-grey'>
                                                {o.colour_full_name?.toLowerCase()}
                                            </span>
                                        </span>
                                    </div>
                                    <div className='product-card-ex__summary__col product-card-ex__summary__reference'>
                                        <span className="text-grey text-small">Reference:</span>
                                        <span className='summary-content-text'>{o.line_reference || 'N/A'}</span>
                                    </div>
                                    <div className='product-card-ex__summary__col product-card-ex__summary__col__subtotal'>
                                        <span className="text-grey text-small">Subtotal:</span>
                                        <span className='text-orange total'>£{roundNumber(o.line_price)}</span>
                                    </div>

                                    <div className='product-card-ex__summary__col product-card-ex__summary__delivery'>
                                        <div className="text-grey text-small">Delivery</div>

                                        <div className="d-flex w-100">
                                            <div className='w-100'>
                                                <img src={BoxSvg} alt="Delivery" />
                                                {
                                                    (o.line_availability && !o.presold) && <span className='summary-content-text'>{o.line_availability.replace('Delivery ', '')}</span>
                                                }
                                                {
                                                    (o.presold) && <span className='summary-content-text'>{o.cart_date}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {config.enablePacks && <>
                                        <div className='product-card-ex__summary__col product-card-ex__summary__width'>
                                            <div className="text-grey text-small">Price (per Pack)</div>
                                            <div className="text-small summary-content-text">£{roundNumber(o?.item_price || 0)}</div>
                                        </div>
                                        <div className='product-card-ex__summary__col product-card-ex__summary__length'>
                                            <div className="text-grey text-small">Packs</div>
                                            <div className="small-grey summary-content-text">{o?.quantity || 0}</div>
                                        </div>
                                    </>
                                    }
                                </div>,
                                )}

                                <div className="row mt-4">
                                    <Button text="Add to Basket" disabled={orderLines.length <= 0}
                                        onClick={onAddOrderSummaryToBasketAndContinue} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="z-indexer" ref={ref}></div>
        </div >
    );
};

export default ProductCardEx;
