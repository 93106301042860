import logo from '../assets/svgs/delta.svg';
import loginImage from '../assets/images/login/login.jpeg';

import { privacyText } from './likewise/privacy';
import { termsText } from './likewise/terms';
import { cookiesText } from './likewise/cookies';

import imgCarpet from './../assets/images/deltacategories/Category-C-Carpet.jpg';
import imgVinyl from './../assets/images/deltacategories/Category-V-Vinyl.jpg';
import imgLaminate from './../assets/images/deltacategories/Category-N-Laminate.jpg';
import imgLVT from './../assets/images/deltacategories/Category-L-LVT.jpg';
import imgCarpetTiles from './../assets/images/deltacategories/Category-T-Carpet Tiles.jpg';

import imgGrass from './../assets/images/Grass.png';
import imgMatting from './../assets/images/Matting.png';
import imgContractFloors from './../assets/images/Contract.png';
import imgScreed from './../assets/images/Screed.png';
import imgProducts from '../assets/images/Products.png';

import search from '../assets/svgs/searchLW.svg';
import cart from '../assets/svgs/cartLW.svg';


import GenericBanner from '../assets/images/Products.png';
import A_Accessories from '../assets/images/category-banners/A_Accessories.jpg';
import B_Adhesives_Screeds from '../assets/images/category-banners/B_Adhesives_Screeds.jpg';

import C_Carpet from '../assets/images/deltacategories/Banner-C-Carpet.jpg';
import H_Underlay from '../assets/images/deltacategories/Amended_Banner_H_Underlay.jpeg';
import L_LVT from '../assets/images/deltacategories/Banner-L-LVT.jpg';
import N_Laminate from '../assets/images/deltacategories/Banner-N-Laminate.jpg';
import T_CarpetTile from '../assets/images/deltacategories/Banner-T-Carpet tiles.jpg';
import V_Banner_Vinyl from '../assets/images/deltacategories/Banner-V-Vinyl.jpg';


import E_Contract from '../assets/images/category-banners/E_Contract.jpg';
import G_Grass from '../assets/images/category-banners/G_Grass.jpg';
import O_Mats_Runners from '../assets/images/category-banners/O_Mats_Runners.jpg';
import Q_Matting from '../assets/images/category-banners/Q_Matting.jpg';
import R_Rugs from '../assets/images/category-banners/R_Rugs.jpg';
import A_Accessories_Image from '../assets/images/categories/Cat_A_Accessories_V2.jpeg';
import B_Adhesives_Screeds_Image from '../assets/images/categories/B_Adhesive_Screeds.jpg';
import E_Contract_Image from '../assets/images/categories/E_Contract.jpg';
import G_Grass_Image from '../assets/images/categories/G_Grass.jpeg';
import O_Mats_Runners_Image from '../assets/images/categories/O_Mats_Runners.jpg';
import Q_Matting_Image from '../assets/images/categories/Q_Matting.jpg';
import R_Rugs_Image from '../assets/images/categories/Cat_R_Rugs_V2.jpeg';

import L_LVT_Image from './../assets/images/deltacategories/Category-L-LVT.jpg';
import N_Laminate_Image from './../assets/images/deltacategories/Category-N-Laminate.jpg';
import C_Carpet_Image from './../assets/images/deltacategories/Category-C-Carpet.jpg';
import T_CarpetTile_Image from './../assets/images/deltacategories/Category-T-Carpet Tiles.jpg';
import V_Banner_Vinyl_Image from './../assets/images/deltacategories/Category-V-Vinyl.jpg';
import H_Underlay_Image from './../assets/images/deltacategories/Amended_Category_H_Underlay.jpeg';
import checked from '../assets/svgs/checked-delta.svg';
import orangeCheckmarkSvg from '../assets/svgs/orange-checkmark-delta.svg';
import orangCircleSvg from '../assets/svgs/orange-circle-delta.svg';
import Warning from '../assets/svgs/danger-sign-delta.svg';
import back from '../assets/images/Delta-Carpets-Back-1.png';

import Matting_accessories from '../assets/images/categories/Matting_Dashboard_accessories_image.jpeg';
import Matting_accessories_banner from '../assets/images/category-banners/Matting_Category_banner_accessories.jpeg';


import Matting_banner_1 from '../assets/images/category-banners/Matting_Dashboard_banner_1.jpg';
import Matting_banner_2 from '../assets/images/category-banners/Matting_Dashboard_banner_2.jpg';
import Matting_banner_3 from '../assets/images/category-banners/Matting_Dashboard_banner_3.jpg';
import { priceListConfig } from './pricelists';

import Clearance_tile from '../assets/images/clearance/delta/Delta_ClearanceSpecials_WebBanner_CategoryCover_V2.jpg';
import { Capacitor } from '@capacitor/core';
import  checkCircle  from '../assets/svgs/check-circle.svg';
import  checkCircleFilled  from '../assets/svgs/check-circle.svg';
import  checkSquare  from '../assets/svgs/check-square.svg';
import  payitBrand  from '../assets/images/Payitbrand.png';

declare let window: any;
const isRunningOnNative = Capacitor.isNativePlatform();

export const config = {
  enablePayments: false,
  site: 'delta',
  payitBrand: payitBrand, 
  checkCircle: checkCircle, 
  checkCircleFilled: checkCircleFilled, 
  checkSquare: checkSquare,
  orderAtoZ: false,
  checked: checked,
  likewiseLogo: logo,
  showAbout: false,
  companyName: 'Delta Carpets',
  copyrightText: 'Delta Carpets. All Rights Reserved.',
  privacyText: privacyText,
  termsText: termsText,
  cookiesText: cookiesText,
  imgCarpet: imgCarpet,
  imgVinyl: imgVinyl,
  imgLaminate: imgLaminate,
  imgLVT: imgLVT,
  imgCarpetTiles: imgCarpetTiles,
  imgGrass: imgGrass,
  imgMatting: imgMatting,
  imgContractFloors: imgContractFloors,
  imgScreed: imgScreed,
  imgProducts: imgProducts,
  GenericBanner: GenericBanner,
  A_Accessories: A_Accessories,
  B_Adhesives_Screeds: B_Adhesives_Screeds,
  C_Carpet: C_Carpet,
  E_Contract: E_Contract,
  G_Grass: G_Grass,
  H_Underlay: H_Underlay,
  L_LVT: L_LVT,
  N_Laminate: N_Laminate,
  O_Mats_Runners: O_Mats_Runners,
  Q_Matting: Q_Matting,
  R_Rugs: R_Rugs,
  T_CarpetTile: T_CarpetTile,
  V_Banner_Vinyl: V_Banner_Vinyl,
  A_Accessories_Image: A_Accessories_Image,
  B_Adhesives_Screeds_Image: B_Adhesives_Screeds_Image,
  C_Carpet_Image: C_Carpet_Image,
  E_Contract_Image: E_Contract_Image,
  G_Grass_Image: G_Grass_Image,
  H_Underlay_Image: H_Underlay_Image,
  L_LVT_Image: L_LVT_Image,
  N_Laminate_Image: N_Laminate_Image,
  O_Mats_Runners_Image: O_Mats_Runners_Image,
  Q_Matting_Image: Q_Matting_Image,
  R_Rugs_Image: R_Rugs_Image,
  T_CarpetTile_Image: T_CarpetTile_Image,
  V_Banner_Vinyl_Image: V_Banner_Vinyl_Image,
  A_Accessories_Matting: Matting_accessories_banner,
  A_Accessories_Image_Matting: Matting_accessories,
  Category_Specials: Clearance_tile,
  Banner_Specials: C_Carpet,
  DOCUMENT_BASE_URL: 'https://deltacarpets.com/',
  API_BASE_URL: 'https://api.deltacarpets.com/api/v1/mayne',
  API_EMAIL_BASE_URL: 'https://api.deltacarpets.com/api/v1/email',
  IMAGE_BASE_URL: 'https://uploads.likewisefloors.co.uk/uploads/',
  IMAGE_BASE_OLD: (isRunningOnNative) ? 'https://clever-shamir.88-208-226-64.plesk.page' : '',
  fillColour: '#C40D3C',
  orangeCheckmarkSvg: orangeCheckmarkSvg,
  orangeCircle: orangCircleSvg,
  Warning: Warning,
  back: back,
  gtag: 'G-HKH4R0J72N',
  suppressHeaderOnSearch: false,
  showCategoryInlineOnCheckout: false,
  showBreadcrumbOnCheckout: false,
  contactHeaderImage: '',
  showContactHeader: false,
  popupPasswordReset: true,
  showColourNames: false,
  showAvailableRollsInline: false,
  searchIcon: search,
  cartIcon: cart,
  cart: {
    deliveryDate: {
      title: 'Delivery Date',
      instructions: [
        "Please enter your chosen delivery date for your full order below. If you would like your order split over different delivery dates, please use the Delivery Date by Line drop down option on the left",
      ]
    }
  },
  pagination: {
    showFirst: true,
    showLast: true,
    prevIcon: false,
    nextIcon: false,
    even: false,
  },
  myAccount: {
    showLogin: false,
    headerImg: '',
  },
  showHomeBanner: false,
  homeBanners: [],
  newImageSolution: true,
  orderHotline: '01787 372988',
  enablePresold: true,
  enableQtyBreaks: true,
  loginPage: {
    register: 'Contact Our Support Team',
    images: [loginImage]
  },
  coretecCodes: [
    'JIA001', 'JIA002', 'USF001', 'USF002', 'USF003', 'USF004', 'USF005', 'USF006', 'USF007',
    'LGH001', 'LGH002'
  ],
  source: 'deltaweb',
  mattingBanner: [
    Matting_banner_1,
    Matting_banner_2,
    Matting_banner_3
  ],
  enablePricelists: true, 
  priceListConfigs: priceListConfig,
  menuLabels: {
    about: 'About',
    account: 'My Account',
    accountInfo: 'Account Information',
    accountSummary: 'Account Summary',
    cats: 'Categories',
    changePassword: 'Change Password',
    contact: 'Contact',
    home: 'Home',
    logout: 'Logout',
    orders: 'My Orders',
    updateAccount: 'Update Account Info',
  },
  dashboard: {
    search: {
      labels: {
        superTitle: '',
      }
    },
    categories: {
      maxInList: -1,                             // -1 to show all
      labels: {
        viewAll: 'View All',
      },
    },
    activities: {
      view: 'quick',
      maxInList: 3,
      arrowColor: '#5d6076',
      labels: {
        title: 'Latest Activities',
        superTitle: '',           // Leave blank for none
        viewAll: 'View All',
      },
    },
  },
  categories: {
    header: {
      labels: {
        title: 'Categories',
      },
      image: '',
    },
    search: {
      labels: {
        superTitle: '',
        title: 'Choose from our wide range of products'
      }
    },
  },
  branch_name: '',
  branch_address1: '',
  branch_address2: '',
  branch_town: '',
  branch_pcode: '',
  branch_phone: '',
  branch_email: '',
  registration_email: 'webenquiries@likewisefloors.co.uk',
  postFullBasket: false,
  enableSpecials: true,
    headerBanner:[
    {
      text: 'Clearance and Specials <strong>Now Available</strong>',
      link: '/clearance'
    }
  ],
  enablePacks: true,
  showSubTitlesOnAccount: false,
  activePricelistCategories: [
    'C', 'N', 'G', 'V', 'N', 'L', 'H', 'E', 'T', 'B', 'A', 'Q', 'J'
  ],
  payit: {
    antiFraudMessage: "Anti Fraud: We will never request your account details (account number or sort code) via email",
    redirectMessage: "We are now redirecting to your bank to complete your payment",
    popupSeconds: 3,
    loadingMessage: "Loading pay by bank",
    bankRedirectionSeconds: 3,

  }
};
