import axios from 'axios';
import c from '../helpers/constants';
import { getApiKey, getBrowserAndDeviceInfo, getCustomerID } from '../helpers/functions';

export function getInvoiceData(customerId: string, invoiceNumber: number, trnno: number) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.GET_INVOICE}?customerid=${encodeURIComponent(customerId)}&invoiceNumber=${invoiceNumber}&trnno=${trnno}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getInvoices(customerId: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LIST_INVOICES}`,
      method: 'POST',
      data:{
        acode: customerId,
        option: 'L',
        company: '4',
        trantype: 'I',
      
      },
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

