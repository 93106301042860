import { PdfHelper } from "../helpers/pdfhelper";
const pdfMake = require('pdfmake'); // PDF generation module
var pdfFonts = require('pdfmake/build/vfs_fonts');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export class InvoiceDefinition {
    // Define class properties
    mainFont = 10;
    tableFont = 8;
    tableFontSize = 9;
    primaryColour = '#2B2B2B';
    secondaryColour = '';
    mainColour = '#898989';
    darkColour = '#747474';
    black = '#333333';
    renderConfig = null;
    startTime = null;
    pageBreaks = [];
    theType: string = '';
    finalName: string = '';
    helper = new PdfHelper();
    inName: string = '';

    // Method to generate pricelist
    async generate(dataset: any, inName: string) {

        this.inName = inName;


        pdfMake.fonts = {
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        };


        // Initialize variables
        let theTable: any = [];
        let typeName = '';
        let z = 0;

        let tHead = dataset['tt-InvHead'][0];
        let tBody = dataset['tt-InvHead'][0]['tt-InvLine'];

        // Extract type from dataset
        // Set primary and secondary colors based on company
        this.primaryColour = this.helper.getPrimaryColour(tHead['brn']);
        this.secondaryColour = this.helper.getSecondaryColour(tHead['brn']);


        let logo = tHead['cologo'];

        console.log(logo[logo.length - 1]);

        let img = await this.helper.resolveLogoByBrand(logo);





        const headerLeft = this.generateHeaderLeft(tHead);
        const headerRight = this.generateHeaderRight(tHead, img as any);
        const addressLeft = this.generateAddressLeft(tHead);


        const footerTerms = this.generateFooterTerms(tHead);

        const footerLeft = this.generateFooterLeft(tHead, footerTerms);
        const footerRight = this.generateFooterRight(tHead);
        const copyright = this.generateCopyright(tHead);



        const productTable = this.generateProductTable(tBody);

        let self = this;
        // Return promise for PDF generation
        return new Promise((resolve) => {
            // Create PDF printer instance

            let customer = dataset.acode;


            let counter = 0;

            // Define header function
            var headerFunction = function (currentPage: any, pageCount: any) {
                return {
                    margin: 8,
                    stack: [
                        {
                            columns: [
                                {
                                    stack: headerLeft,
                                    margin: [0, 0, 5, 0]
                                },
                                {
                                    stack: headerRight,
                                    margin: [5, 0, 10, 0]
                                },
                            ],
                            margin: [10, 5, 10, 0]
                        },
                        {
                            columns: [
                                {
                                    stack: addressLeft,
                                    margin: [0, 0, 5, 0]
                                },
                                {
                                    stack: self.generateAddressRight(tHead, currentPage),
                                    margin: [5, 0, 10, 0]
                                }
                            ],
                            margin: [10, 0, 5, 0]
                        },

                    ]
                };
            }.bind(this);





            let footerCopy = {
                text: copyright,
                fontSize: 8,
                alignment: 'center',
                margin: [0, 10, 0, 0],
                color: this.mainColour
            };

            var footerFunction = function (currentPage: any, pageCount: any) {


                if (currentPage < pageCount) {
                    let footerCop = { ...footerCopy };
                    let f = {
                        margin: [0, 10, 0, 10],
                        width: '100%',
                        stack: [
                            blankLine,
                            footerCop
                        ]
                    };

                    return f;
                }


                console.log(JSON.stringify(footerLeft));
                return {
                    margin: [8, -150, 3, 0],
                    stack: [
                        {
                            columns: [
                                {
                                    stack: footerLeft,
                                    margin: [0, 0, 5, 0]
                                },
                                {
                                    stack: footerRight,
                                    margin: [5, 0, 10, 0]
                                }
                            ],
                            margin: [10, 0, 5, 10]
                        },
                        footerTerms,
                        blankLine,
                        {
                            columns: [
                                copyright
                            ]
                        },

                    ]
                };
            }.bind(this);





            var myTableLayouts = {
                borderedTable: {
                    hLineWidth: function (i: any, node: any) {
                        if (i === 0 || i === node.table.body.length) {
                            return 0;
                        }
                        return (i === node.table.headerRows) ? 1 : 0;
                    },
                    vLineWidth: function (i: any, node: any) {
                        if (counter > 1) {
                            return 0;
                        };
                        return (i === 0 || i === node.table.widths.length) ? 0 : 1;
                    },
                    hLineColor: function (i: any) {
                        return i === 1 ? '#F0F0F0' : '#F0F0F0';
                    },
                    vLineColor: function (i: any) {
                        return '#F0F0F0';
                    },
                    paddingTop: function (i: any, node: any) {
                        return 5;
                    },
                    paddingBottom: function (i: any, node: any) {
                        return 5;
                    },
                    paddingLeft: function (i: any) {
                        return i === 0 ? 5 : 3;
                    },
                    paddingRight: function (i: any, node: any) {
                        return (i === node.table.widths.length - 1) ? 5 : 3;
                    },
                    fillColor: function (i: any, node: any) {
                        return '#FFFFFF'
                    }
                },
                exampleLayout: {
                    hLineWidth: function (i: any, node: any) {
                        if (i === 0 || i === node.table.body.length) {
                            return 0;
                        }
                        return (i === node.table.headerRows) ? 1 : 0;
                    },
                    vLineWidth: function (i: any, node: any) {
                        if (counter > 1) {
                            return 0;
                        };
                        return (i === 0 || i === node.table.widths.length) ? 0 : 1;
                    },
                    hLineColor: function (i: any) {
                        return i === 1 ? '#F0F0F0' : '#F0F0F0';
                    },
                    vLineColor: function (i: any) {
                        return '#F0F0F0';
                    },
                    paddingTop: function (i: any, node: any) {
                        return 5;
                    },
                    paddingBottom: function (i: any, node: any) {
                        return 5;
                    },
                    paddingLeft: function (i: any) {
                        return i === 0 ? 4 : 8;
                    },
                    paddingRight: function (i: any, node: any) {
                        return (i === node.table.widths.length - 1) ? 4 : 8;
                    },
                    fillColor: function (i: any, node: any) {
                        return (i % 2 === 0) ? '#ffffff' : '#F9F9F9';
                    }
                },
                outlinedTable: {
                    hLineWidth: function (i: any, node: any) {
                        if (i === 0 || i === node.table.body.length) {
                            return 1;
                        }
                        return (i === node.table.headerRows) ? 1 : 1;
                    },
                    vLineWidth: function (i: any, node: any) {
                        if (counter > 1) {
                            return 1;
                        };
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i: any) {
                        return i === 1 ? '#333333' : '#333333';
                    },
                    vLineColor: function (i: any) {
                        return '#333333';
                    },
                    paddingTop: function (i: any, node: any) {
                        return 5;
                    },
                    paddingBottom: function (i: any, node: any) {
                        return 5;
                    },
                    paddingLeft: function (i: any) {
                        return i === 0 ? 4 : 8;
                    },
                    paddingRight: function (i: any, node: any) {
                        return (i === node.table.widths.length - 1) ? 4 : 8;
                    },
                    fillColor: function (i: any, node: any) {
                        return (i % 2 === 0) ? '#FFFFFF' : '#FFFFFF';
                    }
                },
                linedTable: {
                    hLineWidth: function (i: any, node: any) {
                        return 1;
                    },
                    vLineWidth: function (i: any, node: any) {

                        return 1;
                    },
                    hLineColor: function (i: any) {
                        return i === 1 ? '#333333' : '#333333';
                    },
                    vLineColor: function (i: any) {
                        return '#333333';
                    },
                    paddingTop: function (i: any, node: any) {
                        return 5;
                    },
                    paddingBottom: function (i: any, node: any) {
                        return 5;
                    },
                    paddingLeft: function (i: any) {
                        return i === 0 ? 4 : 8;
                    },
                    paddingRight: function (i: any, node: any) {
                        return (i === node.table.widths.length - 1) ? 4 : 8;
                    },
                    fillColor: function (i: any, node: any) {
                        return (i % 2 === 0) ? '#FFFFFF' : '#FFFFFF';
                    }
                },
                linedTableSlim: {
                    hLineWidth: function (i: any, node: any) {
                        return 1;
                    },
                    vLineWidth: function (i: any, node: any) {

                        return 1;
                    },
                    hLineColor: function (i: any) {
                        return i === 1 ? '#333333' : '#333333';
                    },
                    vLineColor: function (i: any) {
                        return '#333333';
                    },
                    paddingTop: function (i: any, node: any) {
                        return 2;
                    },
                    paddingBottom: function (i: any, node: any) {
                        return 2;
                    },
                    paddingLeft: function (i: any) {
                        return i === 0 ? 2 : 2;
                    },
                    paddingRight: function (i: any, node: any) {
                        return (i === node.table.widths.length - 1) ? 3 : 3;
                    },
                    fillColor: function (i: any, node: any) {
                        return (i % 2 === 0) ? '#FFFFFF' : '#FFFFFF';
                    }
                },
                blankLineTable: {
                    hLineWidth: function (i: any, node: any) {
                        return 0;
                    },
                    vLineWidth: function (i: any, node: any) {
                        return 0;
                    },
                    hLineColor: function (i: any) {
                        return i === 1 ? '#F0F0F0' : '#F0F0F0';
                    },
                    vLineColor: function (i: any) {
                        return '#F0F0F0';
                    },
                    paddingTop: function (i: any, node: any) {
                        return 0;
                    },
                    paddingBottom: function (i: any, node: any) {
                        return 0;
                    },
                    paddingLeft: function (i: any) {
                        return 0;
                    },
                    paddingRight: function (i: any, node: any) {
                        return 0;
                    }
                },
                subTable: {
                    hLineWidth: function (i: any, node: any) {
                        return 0;
                    },
                    vLineWidth: function (i: any, node: any) {
                        return 0;
                    },
                    hLineColor: function (i: any) {
                        return i === 1 ? '#ffffff' : '#ffffff';
                    },
                    vLineColor: function (i: any) {
                        return '#ffffff';
                    },
                    paddingTop: function (i: any, node: any) {
                        return 0;
                    },
                    paddingBottom: function (i: any, node: any) {
                        return 0;
                    },
                    paddingLeft: function (i: any) {
                        return 0;
                    },
                    paddingRight: function (i: any, node: any) {
                        return 0;
                    }
                }
            };


            let blankLine = {
                width: '100%',
                layout: 'blankLineTable', // optional
                table: {
                    widths: ['*'],
                    headerRows: 0,
                    body: [
                        [
                            {
                                text: ' ',
                                fillColor: this.primaryColour,
                            },
                        ]
                    ]
                }
            };

            theTable.push({
                margin: [0, 15, 0, 0],
                width: '100%',
                layout: 'exampleLayout', // optional
                table: {
                    headerRows: 1,
                    body: productTable,
                    widths: [20, '*', 50, 50, 50, 50, 50]
                }
            });


            theTable.push({
                width: '100%',
                layout: 'blankLineTable', // optional
                table: {
                    headerRows: 1,
                    body: [
                        [
                            {
                                text: ' ',
                            },
                            { text: "NOTES: \nnone", color: this.darkColour }
                        ]
                    ],
                    widths: [20, '*']
                },
                margin: [0, 10, 0, 300]
            });

            theTable.push({
                text: '  ',
            });


            var docDefinition = {

                pageMargins: [20, 270, 20, 70],
                footer: footerFunction,
                header: headerFunction,
                content: theTable,
                styles: {
                    header: {
                        fontSize: 9,
                        bold: true,
                        alignment: 'right',
                        margin: [0, 190, 0, 80]
                    },
                    subheader: {
                        fontSize: 9
                    },
                    superMargin: {
                        margin: [20, 0, 40, 0],
                        fontSize: 15
                    },
                    cellWithPadding: {
                        margin: [20, 0, 0, 0]
                    }
                }
            };


            theTable.push({
                margin: [0, 15, 0, 0],
                width: '100%',
                layout: 'exampleLayout', // optional
                table: {
                    headerRows: 1,
                    body: productTable,
                    widths: [20, '*', 50, 50, 50, 50, 50]
                }
            });


            theTable.push({
                width: '100%',
                layout: 'blankLineTable', // optional
                table: {
                    headerRows: 1,
                    body: [
                        [
                            {
                                text: ' ',
                            },
                            { text: "NOTES: \nnone", color: this.darkColour }
                        ]
                    ],
                    widths: [20, '*']
                },
                margin: [0, 10, 0, 400]
            });

            theTable.push({
                text: '  ',
            });


            var docDefinition = {

                pageMargins: [20, 190, 20, 70],
                footer: footerFunction,
                header: headerFunction,
                content: theTable,
                styles: {
                    header: {
                        fontSize: 9,
                        bold: true,
                        alignment: 'right',
                        margin: [0, 190, 0, 80]
                    },
                    subheader: {
                        fontSize: 9
                    },
                    superMargin: {
                        margin: [20, 0, 40, 0],
                        fontSize: 15
                    },
                    cellWithPadding: {
                        margin: [20, 0, 0, 0]
                    }
                }
            };

            pdfMake.tableLayouts = myTableLayouts;

            pdfMake.createPdf(docDefinition).getBlob((blob: any) => {
                // Create a URL for the PDF blob
                const url = URL.createObjectURL(blob);
                // Open the PDF in a new window
                window.open(url, '_blank');
            });
        });
    }

    getTable(data: any, renderConfig: any, clearLine: any) {

        //clearLine
        return [];

    }


    renderRow(renderConfig: any, data: any, itm: any, indent: boolean = false) {
        return [];
    }




    doReplace = (actionData: any, data: any) => {
        if (actionData == null || actionData.length == 0) {
            return '';
        }
        return actionData.split(data[0]).join(data[1]);

    };
    doFormatCurrency = (actionData: any, data: any) => {
        return actionData;
    };











    generateHeaderLeft(data: any) {
        return [
            {
                text: `${data.coname} ${data.cotel}`,
                alignment: 'left',
                margin: [0, 0, 0, 10],
                fontSize: 10,
                color: this.darkColour
            },
            {
                layout: 'outlinedTable', // optional,
                table: {
                    widths: ['*'],
                    layout: 'outlinedTable', // optional
                    body: [
                        // Table headers
                        [
                            {
                                verticalAlign: 'bottom',
                                columns: [
                                    {
                                        text: 'INVOICE',
                                        color: this.black,
                                        fontSize: 18,
                                        bold: true,
                                        width: 75,
                                        verticalAlign: 'bottom',
                                        margin: [0, 0, 0, 0]
                                    },
                                    {
                                        text: 'RE-PRINT',
                                        color: 'red',
                                        align: 'left', fontSize: 14,
                                        margin: [5, 4, 0, 0],
                                        verticalAlign: 'bottom'
                                    },
                                ]
                            }
                        ],
                    ]
                },
                alignment: 'left',
                margin: [0, 0, 0, 5],
            },
            {
                layout: 'outlinedTable', // optional,
                table: {
                    widths: ['*'],
                    body: [
                        // Table headers
                        [
                            {
                                columns: [
                                    {
                                        text: 'Customer Account No.',
                                        color: this.black,
                                        bold: true,
                                        margin: [0, 0, 0, 0]
                                    },
                                    {
                                        text: data.acode, bold: true, color: this.black, margin: [0, 0, 0, 0]
                                    },
                                ]
                            }
                        ],
                    ]
                },
                alignment: 'left',
                margin: [0, 0, 0, 5],
                fontSize: 11
            },
        ];
    }

    generateAddressLeft(data: any) {
        return [
            {
                layout: 'blankLineTable', // optional,
                table: {
                    widths: ['*'],
                    body: [
                        // Table headers
                        [
                            { text: this.getInvoiceAddress(data), bold: false, color: this.black, margin: [0, 5, 5, 58] },
                        ],
                    ],
                    height: 100
                },
                alignment: 'left',
                margin: [0, 0, 0, 0],
                fontSize: 10,
            },
        ];
    }

    generateAddressRight(data: any, pageIndex: number) {
        let lineMargin = [0, 0, 0, 4];
        return [
            {
                columns: [
                    { text: 'Page ' + pageIndex, bold: false, color: this.black, width: 90 },
                    { text: 'Bank: ' + data.bsortno + '  ' + data.baccno, color: this.black }
                ],
                alignment: 'left', margin: [0, 0, 0, 5], fontSize: this.mainFont
            },
            {
                layout: 'linedTable', // optional,
                fontSize: this.mainFont,
                table: {
                    widths: [90, '*'],
                    body: [
                        // Table headers
                        [
                            { text: 'Invoice No', bold: true, color: this.black, width: 70, fontSize: this.mainFont, margin: lineMargin },
                            { text: data.invnum, color: this.black, fontSize: this.mainFont, margin: lineMargin }
                        ],
                        [
                            { text: 'Job No', bold: true, color: this.black, width: 70, margin: lineMargin },
                            { text: data.ordnum, color: this.black, margin: lineMargin },
                        ],
                        [
                            { text: 'Invoice Date', bold: true, color: this.black, width: 70, margin: lineMargin },
                            { text: this.formatDate(data.invdatedesc), color: this.black, margin: lineMargin },
                        ],
                        [
                            { text: 'Reference', bold: true, color: this.black, width: 70, margin: lineMargin },
                            { text: data.cusref, color: this.black, margin: lineMargin },
                        ],
                        [
                            { text: 'Vat No.', bold: true, color: this.black, width: 70, margin: lineMargin },
                            { text: data.vatno, color: this.black, margin: lineMargin },
                        ],
                        [
                            { text: ' ', bold: false, color: this.black, width: 70, margin: lineMargin },
                            { text: '', color: this.black, margin: lineMargin },
                        ]
                    ]
                }
            },
        ];

    }

    generateHeaderRight(data: any, img: string) {
        return [
            { image: img, alignment: 'center', width: 140, margin: [0, 10, 10, 10] },
        ];
    }

    generateFooterLeft(data: any, footerTerms: any) {
        return [
            {
                layout: 'outlineTable', // optional,

                table: {
                    widths: ['*'],
                    body: [
                        // Table headers
                        [{

                            margin: [0, 4, 4, 4],
                            columns: [
                                {
                                    text: 'Site Address',
                                    color: this.black,
                                    width: 70,
                                    bold: true,
                                },
                                {
                                    text: this.getCustomerAddress(data),
                                    color: this.black,
                                    align: 'left'
                                },
                            ]
                        }
                        ]
                    ]
                },
                alignment: 'left', margin: [0, 0, 0, 10], fontSize: this.mainFont
            },
            footerTerms,
            {
                columns: [
                    {
                        text: "Bank:",
                        width: 60, color: this.black
                    },
                    {
                        text: this.helper.rightTrim(data.baname, '.') + ' - ' + data.bname, color: this.black
                    }
                ],
                alignment: 'left', margin: [0, 3, 0, 0], fontSize: this.mainFont
            },
            {
                columns: [
                    {
                        text: "Sort Code:",
                        color: this.black,
                        width: 60,
                    },
                    {
                        text: data.bsortno + '   Account:' + data.baccno, color: this.black
                    }
                ],
                alignment: 'left', margin: [0, 3, 0, 0], fontSize: this.mainFont,
            },
        ]

    }

    getInvoiceAddress(dataset: any) {
        const keys = ["name", "address1", "address2", "city", "county", "country", "pcode"];
        const result = keys.filter(key => dataset[key] !== "").map(key => `${dataset[key]}`).join("\n");
        return result;
    }

    getCustomerAddress(dataset: any) {
        const keys = ["dname", "daddress1", "daddress2", "dxity", "dcounty", "dcountry", "dpcode"];
        const result = keys.filter(key => dataset[key] !== "" && dataset[key]).map(key => `${dataset[key]}`).join("\n");
        return result;
    }


    generateFooterRight(data: any) {

        const dataList = [
            { text: 'Net Amount', key: "nval" },
            { text: 'VAT Amount', key: "vval" },
            { text: 'Gross Amount', key: "gval" },
            { text: 'Paid To Date', key: "paid" },
            { text: 'Total Now Due', key: "balance" },
        ];

        let lines = [] as any;
        dataList.forEach(x => {

            let val = data[x.key] ? data[x.key] : '';
            val = `${val}`.replace('£', '').trim();
            val = val == '' ? '0.00' : val;

            let size = x.key == 'balance' ? 14 : this.mainFont;
            let color = x.key == 'balance' ? this.black : this.black;

            lines.push([
                { text: x.text, bold: true, color: this.black, width: 120, fontSize: size, margin: [4, 4, 0, 4] },
                {
                    text: data.currency, color: color, fontSize: size, bold: true,
                    width: 30,
                    border: [true, true, false, true]
                },
                {
                    text: val, color: color, fontSize: size, bold: true,
                    alignment: 'right',

                }
            ]);
        });

        return [
            {
                layout: 'linedTableSlim', // optional
                table: {
                    widths: [140, 30, '*'],
                    body: lines
                },
            }
        ];
    }

    generateCopyright(data: any) {
        return [
            {
                text: data.footer1 + "\n" + data.footer2,
                color: this.darkColour, fontSize: 9, alignment: 'center', margin: [0, 10, 0, 0],
                bold: false
            }
        ]
    }


    generateProductTable(tBody: any) {
        let lh = [
            {
                text: 'Ln', fontSize: this.mainFont
            },
            {
                text: 'Description', fontSize: this.mainFont
            },
            {
                text: 'Lth/Qty', fontSize: this.mainFont
            },
            {
                text: 'Units', fontSize: this.mainFont
            },
            {
                text: 'Price', fontSize: this.mainFont
            },
            {
                text: 'Disc', fontSize: this.mainFont
            },
            {
                text: 'Total', fontSize: this.mainFont
            }
        ];

        if (!tBody || tBody.length == 0) {
            return [lh];
        }


        let rows = [] as any;
        tBody.forEach((x: any) => {
            let row = [];
            let details = [];
            let keys = ['qual', 'des', 'lncusref'];

            const result = keys.filter(key => x[key] !== "").map(key => `${x[key]}`).join("\n");

            row.push({ text: x.ordline, fontSize: this.mainFont, color: this.darkColour });
            row.push({ text: result, fontSize: this.mainFont, color: this.darkColour });
            row.push({ text: x.qty, fontSize: this.mainFont, color: this.darkColour });
            row.push({ text: x.uom, fontSize: this.mainFont, color: this.darkColour });
            row.push({ text: x.uprice.toFixed(2), fontSize: this.mainFont, color: this.darkColour });
            row.push({ text: x.disc && x.disc != '' ? parseFloat(`${x.disc}`).toFixed(2) : '', fontSize: this.mainFont, color: this.darkColour });
            row.push({ text: x.lval.toFixed(2), fontSize: this.mainFont, color: this.darkColour });
            rows.push(row);

        });


        return [
            lh,
            ...rows
        ];


    }

    generateFooterTerms(data: any) {

        let terms2 = '';
        let termsArr = [] as any;
        let termsKeys = ['terms-Line1', 'terms-Line2', 'terms-Line3', 'terms-Line4'];

        termsKeys.forEach(x => {
            if (data.hasOwnProperty(x) && data[x] && data[x] != '') {
                termsArr.push(data[x]);
            }
        });

        terms2 = termsArr.join("\n");

        return {
            stack: [
                {

                    text: data.terms, alignment: 'left', margin: [0, 0, 0, 5], fontSize: 9, color: this.black
                },
                {
                    text: terms2, alignment: 'left', margin: [0, 0, 0, 5], fontSize: 9, color: this.black

                },
            ]
        };


    }



    formatDate(originalDate: string) {


        if (originalDate.indexOf('-') === -1) {
            return originalDate;
        }

        // Convert the string to a Date object
        const date = new Date(originalDate);

        // Format the date to "Day dd/mm/yyyy"
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
            weekday: 'short',  // "Thur"
            day: '2-digit',    // "31"
            month: '2-digit',  // "10"
            year: 'numeric'    // "2024"
        }).format(date);
        return formattedDate;

    }
}