import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { emailNewPassword } from '../../actions/Email';
import CONSTANTS from '../../helpers/constants';
import Button from '../Button';
import LWModal from '../Modal/Modal';
import TextInput from '../TextInput';
import './ChangePassword.scss';
import {
    getCustomerID,
    setCustomerBranch,
    setCustomerStatus,
} from '../../helpers/functions';
import { getCustomer } from '../../selectors/Customer';

const ChangePassword = () => {
    const dispatch = useDispatch();
    const [emailSent, setEmailSent] = useState(false);
    const [name, setName] = useState('');
    const [cname, setCName] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRepassword] = useState('');
    const customer = useSelector(getCustomer);

    useEffect(() => {
        setCustomerId(getCustomerID() as string);
    }, [dispatch]);


    const onChangePassword = async () => {
        await dispatch(emailNewPassword(
            {
                contactEmail: email,
                contactName: cname,
                contactTel: tel,
                customerName: name,
                customerId: customerId,
                password: password,
                branchEmail: customer?.branch_email || ''
            },
        ));
        setCustomerId('');
        setCustomerBranch('');
        setCustomerStatus('');
        setName('');
        setCName('');
        setEmail('');
        setTel('');
        setPassword('');
        setRepassword('');
        setEmailSent(true);
    };



    return (<>

        {!emailSent &&
            <div className="change-password-form">
                <div className="row  mb-2">
                    <div className="col-12">
                        <TextInput label="Customer Account Number" id="customerId" bgColor="grey" name="customerId" disabled={true}
                            value={customerId} onBlur={(e) => setCustomerId(e)}
                            onChange={(e) => setCustomerId(e)}
                            placeholder="Enter your customer account number here" />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <TextInput label="Customer Name" id="name" bgColor="grey" name="name"
                            value={name} onBlur={(e) => setName(e)}
                            onChange={(e) => setName(e)}
                            placeholder="Enter your customer name here" />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <TextInput label="Contact Name" id="cname" bgColor="grey" name="cname"
                            value={cname} onBlur={(e) => setCName(e)}
                            onChange={(e) => setCName(e)}
                            placeholder="Enter your contact name here" />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <TextInput label="Contact Email" id="email" bgColor="grey" name="email"
                            value={email} onBlur={(e) => setEmail(e)}
                            onChange={(e) => setEmail(e)}
                            placeholder="Enter your contact email here" />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <TextInput label="Contact Phone" id="tel" bgColor="grey" name="tel"
                            value={tel} onBlur={(e) => setTel(e)}
                            onChange={(e) => setTel(e)}
                            placeholder="Enter your contact phone here" />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <TextInput label="New Password Request" id="password" bgColor="grey" name="password"
                            value={password} onBlur={(e) => setPassword(e)}
                            onChange={(e) => setPassword(e)}
                            type="password"
                            placeholder="Enter your new password here" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextInput label="Confirm New Password Request" id="tel" bgColor="grey" name="repassword"
                            value={rePassword} onBlur={(e) => setRepassword(e)}
                            type="password"
                            onChange={(e) => setRepassword(e)}
                            placeholder="Re-enter your password here" />
                    </div>
                </div>
                {(password && rePassword && password != rePassword) && <div className="alert alert-danger mt-2" role="alert">Your password and password confirmation must match</div>}
                <div className="row mt-5">
                    <Button text="Request Password Update" disabled={!customerId || !email || !tel || !cname || !name || !password || !rePassword || password != rePassword} onClick={onChangePassword} />
                </div>
            </div>
        }
        {emailSent && <div className="alert alert-success" role="alert">
            Thank you for your request, our team will be in touch soon.
        </div>}
    </>


    );
};

export default ChangePassword;
