import {
    FETCH_INVOICES_REQUEST,
    FETCH_INVOICES_RESPONSE,
    FETCH_INVOICES_FAILURE,
    InvoiceState,
    InvoiceActionTypes,
  } from '../types/Invoice';
  
  const initialState: InvoiceState = {
    invoices: [],
    isFetchingInvoices: false,
    fetchingInvoicesFailed: false,
  };
  
  const invoiceReducer = (
    state = initialState,
    action: InvoiceActionTypes,
  ): InvoiceState => {
    switch (action.type) {
      case FETCH_INVOICES_REQUEST:
        return {
          ...state,
          invoices: [],
          isFetchingInvoices: true,
          fetchingInvoicesFailed: false,
        };
      case FETCH_INVOICES_RESPONSE:
        return {
          ...state,
          invoices: action.payload || [],
          isFetchingInvoices: false,
          fetchingInvoicesFailed: false,
        };
      case FETCH_INVOICES_FAILURE:
        return {
          ...state,
          invoices: [],
          isFetchingInvoices: false,
          fetchingInvoicesFailed: true,
        };
      default:
        return state;
    }
  };
  
  export default invoiceReducer;