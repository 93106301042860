import React, { useEffect, useState } from 'react';
import '../styles/Thankyou.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../helpers/functions';
import { config } from '../config/config';

const PayByLink = () => {


  const query = useQuery();


  useEffect(() => {
    let p = {
      data: query.get('data') || '',
    };

    console.log(p);
    if (p.data == '') {
      const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
      window.location.href = baseURL;
      return;
    }
    setTimeout(() => {
      window.location.href = config.API_BASE_URL.replace('/v1/mayne', '') + `/paymentlink?data=${p.data}`;
    }, 3000);
  }, []);


  return (


    <div className="thankyou">
      <div className="container">

        <div className='thankyou-header text-white'>
          <span className='ml-2'>&nbsp;</span>
        </div>


        <div className='thankyou-card'>
          <div className='thankyou-card-header'>

            <div className='paybylink-branding'>
              <img src={config.likewiseLogo} className="navbar-brand" alt={config.companyName + ' Logo'} />
            </div>

            <div className='anti-fraud mb-4 mt-4'>
              {
                config.payit.antiFraudMessage
              }
            </div>

            <div className=''>
              <div className=''>
                <p>
                  You will now be redirected to your bank to complete your payment.
                </p>
              </div>
              <div className='the-loader'>
                <div className="loader"></div>
              </div>
            </div>


            <div className='payit-branding'>
              <img src={config.payitBrand} alt="payit brand" />
            </div>


          </div>
        </div>



      </div>
    </div>

  );
};

export default PayByLink;
