export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_RESPONSE = 'FETCH_INVOICES_RESPONSE';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';

export interface Invoice {

}

export interface InvoiceState {
  invoices: any[],
  isFetchingInvoices: boolean;
  fetchingInvoicesFailed: boolean;
}

interface FetchInvoicesRequestAction {
  type: typeof FETCH_INVOICES_REQUEST;
  payload: null;
}

interface FetchInvoicesResponseAction {
  type: typeof FETCH_INVOICES_RESPONSE;
  payload: any[];
}

interface FetchInvoicesFailureAction {
  type: typeof FETCH_INVOICES_FAILURE;
  payload: null;
}

export type InvoiceActionTypes =
  | FetchInvoicesRequestAction
  | FetchInvoicesResponseAction
  | FetchInvoicesFailureAction;
