import axios from 'axios';
import c from '../helpers/constants';
import { getApiKey, getBrowserAndDeviceInfo, getCustomerBranch, getCustomerID } from '../helpers/functions';

/**
 * Trigger Order Payment 
 * @param orderNumber 
 * @returns 
 */
export function fetchThankyouDetails(payload: any) {


  payload.customer_number = getCustomerID(); 

  return (
    axios({
      url: `${c.API_ENDPOINTS.THANKYOU_DETAILS}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': getApiKey()
      },
      data: JSON.stringify(payload),
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function fetchPaybyLinkThankyou( payload : any ){

  return (
    axios({
      url: `${c.API_ENDPOINTS.PAYBY_LINK_COMPLETE}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(payload),
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
