import React, { useEffect, useState } from 'react';
import '../styles/Thankyou.scss';
import View from '../components/View';
import _ from 'lodash';
import CardCheckout from '../components/CardCheckout';
import MobileMenuBottom from '../components/MobileBottomMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../selectors/Order';
import { fetchOrders } from '../actions/Order';
import { useQuery } from '../helpers/functions';
import dateFormat from 'date-fns/format';
import Button from '../components/Button';
import { config } from '../config/config';
import c from '../helpers/constants';
import history from '../helpers/history';
import { CheckCircle, CheckSquare, CircleFilled, CrossCircle } from '../helpers/icons';
import { useParams } from 'react-router-dom';
import { getThankyou } from '../selectors/Thankyou';
import { fetchThankyou } from '../actions/Thankyou';


const ThankYou = () => {

  const [params, setParams] = useState<any | null>(null);

  const details = useSelector(getThankyou);
  const dispatch = useDispatch();


  console.log(details);

  const query = useQuery();


  useEffect(() => {
    console.log(params);
    if (null == params) {
      let p = {
        e: query.get('e') || '',
        a: query.get('a') || '',
        t: query.get('t') || '',
        s: query.get('s') || '',
      };
      console.log(p);
      setParams(p);
      dispatch(fetchThankyou(p));
    }
  }, []);


  let status = details ? details.status : '';
  let paymentString = '';
  let paymentObj = CheckCircle();

  switch (status) {
    case 'PaymentApproved':
      paymentString = 'Succesful';
      break;
    case 'PaymentRejected':
      paymentString = 'Rejected';
      paymentObj = CrossCircle();
      break;
    case 'PaymentInProgress':
      paymentString = 'In Progress';
      break;
    case 'PaymentStatusUnknown':
      paymentString = 'Succesful';
      break;
    case 'CustomerAbortedJourney':
      paymentString = 'Cancelled';
      paymentObj = CrossCircle();
      break;
    case 'TechnicalError':
      paymentString = 'Error';
      paymentObj = CrossCircle();
      break;
    case 'OtherError':
      paymentString = 'Error';
      paymentObj = CrossCircle();
      break;
  }



  const printPage = () => {
    window.print();
  };

  const backToStatements = () => {
    history.push(c.APP_ROUTES.MY_STATEMENTS);
  };

  let subtotal = 0;
  if (details && details.data?.invoices) {
    subtotal = details.data?.invoices
      .map((y: any) => parseFloat((y.amount as string))) // Remove '£' and convert to number
      .reduce((acc: number, val: number) => acc + val, 0); // Sum up the amounts

  }

  return (


    <div className="thankyou">
      <div className="container">

        <div className='thankyou-header text-white'>
          {paymentString == 'Succesful' &&
            <span className='check-circle'>{paymentObj}</span>
          } {paymentString != 'Succesful' &&
            <span className='cross-circle'>{paymentObj}</span>
          }
          <span className='ml-2'>Payment {paymentString}</span>
        </div>

        {details != null && <>
          <div className='thankyou-card'>
            <div className='thankyou-card-header'>
              <h2 className='thankyou-card__total text-dark-grey mb-1'>&pound;{details.data.order_value.toFixed(2)} </h2>
              {paymentString == 'Succesful' && <div className='thankyou-card__status text-dark-grey'><span className='check-circle-green'>{CircleFilled()}</span>Paid Successfully</div>}

            </div>
            <div className='thankyou-card-body'>
              <h5 className="grey-text order-summary-title  text-grey" >Summary</h5>
              {paymentString == 'Successful' &&
                <h5 className='dark-text order-summary-subtitle pb-2'>Payment Received</h5>
              }

              <div className='thankyou-table mt-2'>

                <div className='thankyou-table__row border-bottom pb-3 order-number-row'>
                  <div className='thankyou-table__cell text-grey'>Payment Date</div>
                  <div className='thankyou-table__cell text-dark-grey'>{ details.data?.created_at}</div>
                </div>
                <div className='thankyou-table__row border-bottom pb-3 order-number-row'>
                  <div className='thankyou-table__cell text-grey'>Payment Reference</div>
                  <div className='thankyou-table__cell text-dark-grey'>{details.data?.transaction_id}</div>
                </div>
                {
                  details.type == 'order' && <>

                    <div className='thankyou-table__row border-bottom pb-3 order-number-row'>
                      <div className='thankyou-table__cell text-grey'>Order Number.</div>
                      <div className='thankyou-table__cell text-dark-grey'>{details.data?.order_number}</div>
                    </div>
                  </>
                }

                {
                  details.type != 'order' && <>

                    <div className='thankyou-table__row'>
                      <div className='thankyou-table__cell text-grey'>Payments Against Invoices</div>
                      <div className='thankyou-table__cell text-dark-grey'>£{details.data?.order_value.toFixed(2)}</div>
                    </div>
                    <div className='thankyou-table__row mb-2'>
                      <div className='thankyou-table__cell  text-grey'>Selected Invoices</div>
                      <div className='thankyou-table__cell text-dark-grey'>{details.data?.invoices.length}</div>
                    </div>

                    {
                      details.data?.invoices && details.data?.invoices.map((x: any) => (

                        <div className='thankyou-table__row invoice-row mb-2'>
                          <div className='thankyou-table__cell'><span className="check-square">{CheckSquare()}</span><span>Invoice No. {x.invoice}</span></div>
                          <div className='thankyou-table__cell text-dark-grey'>£{parseFloat(" " + x.amount).toFixed(2)}</div>
                        </div>
                      ))
                    }

                  </>
                }


                {
                  details.type != 'order' && <>


                    <div className='thankyou-table__row pt-3 border-top'>
                      <div className='thankyou-table__cell text-grey'>Allocated to Invoices</div>
                      <div className='thankyou-table__cell text-dark-grey thank-you-total'>£{subtotal.toFixed(2)}</div>
                    </div>
                  </>
                }

                <div className='thankyou-table__row pb-3 '>
                  <div className='thankyou-table__cell text-grey'>Total Paid</div>
                  <div className='thankyou-table__cell text-dark-grey thank-you-total'>£{details.data?.order_value.toFixed(2)}</div>
                </div>

                {
                  (details.type != 'order' && paymentString == 'Successful') && <>

                    <div className='thankyou-table__row pt-3 pb-3 border-top'>
                      <div className='thankyou-table__cell text-grey'>Remaining Balance</div>
                      <div className='thankyou-table__cell text-dark-grey thank-you-total'>£0.00</div>
                    </div>
                  </>
                }
              </div>

              <div className='text-center mt-3'>
                <div className='d-flex justify-content-center align-items-center action-buttons'>
                  <div className='mr-1'>
                    <Button type='orange' size='md' shape='chamfer' text="Back to Statements" onClick={() => backToStatements()}></Button>
                  </div>
                  <div className='ml-5'>
                    <Button type='grey' size='md' shape='chamfer' text="Print Confirmation" onClick={() => printPage()}></Button>
                  </div>
                </div>


              </div>

              <div className='payit-branding'>
                <img src={config.payitBrand} alt="payit brand" />
              </div>


            </div>
          </div>
        </>
        }

      </div>
    </div>

  );
};

export default ThankYou;
